import { LogglyTracker } from 'loggly-jslogger';

const logger = new LogglyTracker();

logger.push({ logglyKey: process.env.REACT_APP_LOGGLY_CLIENT_TOKEN });

export enum LogLevels {
  INFO = 'INFO',
  ERROR = 'ERROR',
  DEBUG = 'DEBUG',
  WARNING = 'WARNING',
}

export const getLogInfo = () => {
  return {
    name: 'pannelist_webapp_frontend',
  };
};

export const getLogContext = (additionalInfo: Record<string, any> = {}) => {
  return {
    ...additionalInfo,
    userAgent: navigator.userAgent,
    environment: process.env.NODE_ENV,
  };
};

export const log = {
  /* eslint-disable no-console */
  info: (
    message: string,
    eventId: number,
    additionalInfo?: Record<string, any>
  ) => {
    if (process.env.NODE_ENV === 'development') {
      console.log(message, eventId, getLogContext(additionalInfo));
    } else {
      logger.push({
        ...getLogInfo(),
        message,
        eventId,
        levelname: LogLevels.INFO,
        context: getLogContext(additionalInfo),
      });
    }
  },
  error: (
    message: string,
    eventId: number,
    error: any,
    additionalInfo?: Record<string, any>
  ) => {
    if (process.env.NODE_ENV === 'development') {
      console.error(error);
      console.log(message, eventId, error, getLogContext(additionalInfo));
    } else {
      logger.push({
        ...getLogInfo(),
        message,
        eventId,
        error,
        levelname: LogLevels.ERROR,
        context: getLogContext(additionalInfo),
      });
    }
  },
  /* eslint-ensable no-console */
};
