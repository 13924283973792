import { CheckGrey, CheckGreen } from 'assets/';
import { useOnboardingModal } from '../../OnboardingModalContext';
import { StepsItem, StepsContainer, StepsItemText, CoinIcon } from './styled';

export const OnboardingSteps = () => {
  const { config } = useOnboardingModal();

  if (!config) {
    return null;
  }

  return (
    <>
      {config.steps && (
        <StepsContainer>
          {config.steps.map((step, index) => (
            <StepsItem key={index}>
              {!step.isText &&
                (step.isCompleted ? <CheckGreen /> : <CheckGrey />)}
              <StepsItemText $isCompleted={step.isCompleted}>
                {step.label}
              </StepsItemText>
              {step.hasCoinIcon && <CoinIcon />}
            </StepsItem>
          ))}
        </StepsContainer>
      )}
    </>
  );
};
