import { Typography } from 'antd';
import styled from 'styled-components';
import { color } from 'helpers/';

const { Title: AntdTitle, Text } = Typography;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100vw;
  height: 100vh;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  margin: auto;
  max-width: 464px;
  padding: 0 8px;
  text-align: center;
  align-items: center;
  img {
    max-width: 100vw;
    max-height: 320px;
  }
`;

export const Title = styled(AntdTitle)`
  && {
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
    color: ${color('neutral.1000')};
  }
`;

export const Description = styled(Text)`
  && {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${color('neutral.700')};
    padding-bottom: 32px;
  }
`;
