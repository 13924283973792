import { useTheme } from 'styled-components';
import { EventIds } from 'constants/';
import {
  Palettes,
  ColorOpacities,
  Theme,
  RadiusSizes,
  BreakpointSizes,
  mediaQueryType,
} from 'theme/';
import { log } from 'utils/logService';

export type MapOpacities<T> = T extends string
  ? T | `${T}.${ColorOpacities}`
  : never;
export type ColorPath = MapOpacities<Palettes>;

export const color =
  (path: ColorPath) =>
  ({ theme }: { theme: Theme }): string => {
    const parts = path.split('.');
    const palette = parts[0];
    const opacity = parts[1] || '600';

    if (!palette || !Object.keys(theme.palette).includes(palette)) {
      log.info(
        'Theme color helper was provided with wrong theme path',
        EventIds.ThemeHelperError,
        { path }
      );
      return '';
    }

    return theme.palette[palette as Palettes][opacity as ColorOpacities];
  };

export const radius =
  (size: RadiusSizes) =>
  ({ theme }: { theme: Theme }): string => {
    if (!size || !Object.keys(theme.radius).includes(size)) {
      log.info(
        'Theme radius helper was provided with wrong size',
        EventIds.ThemeHelperError,
        { size }
      );
      return '';
    }

    return theme.radius[size];
  };

export const breakpoint =
  (size: BreakpointSizes, mediaQueryType: mediaQueryType = 'max-width') =>
  ({ theme }: { theme: Theme }): string => {
    if (!size || !Object.keys(theme.breakpoint).includes(size)) {
      log.info(
        'Theme breakpoint helper was provided with wrong size',
        EventIds.ThemeHelperError,
        { size }
      );
      return '';
    }

    return `@media (${mediaQueryType}: ${theme.breakpoint[size]}px)`;
  };

export const getThemeHelper = <
  T extends (...args: any[]) => (props: { theme: Theme }) => string
>(
  func: T,
  theme: Theme
) => {
  return (...args: Parameters<T>) => func(...args)({ theme });
};

export const useThemeHelper = <
  T extends (...args: any[]) => (props: { theme: Theme }) => string
>(
  func: T
) => {
  const theme = useTheme();
  return getThemeHelper(func, theme);
};

export const stripUnits = (value: string) => Number.parseFloat(value);
