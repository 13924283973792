import { SVGProps } from 'react';

export const ArrowLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.76615 3.64844L2.41444 8.00015L6.76615 12.3519L7.90283 11.2152L4.68781 8.00015L7.90283 4.78512L6.76615 3.64844Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.55117 8.80404L14 8.80405L14 7.19653L3.55117 7.19653V8.80404Z"
      fill="currentColor"
    />
  </svg>
);
