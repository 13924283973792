import React from 'react';
import { useCurrentUserQuery } from 'store/api';
import { Trans } from '@lingui/macro';
import { useAppSelector } from 'store/';
import { getAuthStore } from 'store/slices';
import { Container, Content, Description, Title } from './styled';

export const BlockedUser = ({ children }: { children: React.ReactNode }) => {
  const { isLoggedIn } = useAppSelector(getAuthStore);

  const { data, isLoading } = useCurrentUserQuery(undefined, {
    skip: !isLoggedIn,
  });

  if (!isLoading && data?.user_profile.is_blocked) {
    return (
      <Container>
        <Content>
          <Title>
            <Trans>Just a few more steps!</Trans>
          </Title>
          <Description>
            <Trans>
              Thanks for applying to be a panelist with Cherries! We&apos;ll be
              in touch with next steps to verify your account.
            </Trans>
          </Description>
        </Content>
      </Container>
    );
  }

  return <>{children}</>;
};
