import { SVGProps } from 'react';

export const LockIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.5 7.5V13.5H11.5V7.5H4.5ZM4 6C3.44772 6 3 6.44772 3 7V14C3 14.5523 3.44772 15 4 15H12C12.5523 15 13 14.5523 13 14V7C13 6.44772 12.5523 6 12 6H4Z"
      fill="#383B48"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.25 5C4.25 2.92893 5.92893 1.25 8 1.25C10.0711 1.25 11.75 2.92893 11.75 5V7H10.25V5C10.25 3.75736 9.24264 2.75 8 2.75C6.75736 2.75 5.75 3.75736 5.75 5V7H4.25V5Z"
      fill="#383B48"
    />
    <circle cx="8" cy="10" r="1" fill="#383B48" />
  </svg>
);
