import { SVGProps } from 'react';

export const Settings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M10.625 1L10.1899 3.9541C9.25366 4.16393 8.37909 4.53548 7.59302 5.03369L5.19482 3.25049L3.25049 5.19482L5.03369 7.59302C4.53548 8.37909 4.16393 9.25366 3.9541 10.1899L1 10.625V13.375L3.9541 13.8101C4.16393 14.7463 4.53548 15.6209 5.03369 16.407L3.25049 18.8052L5.19482 20.7495L7.59302 18.9663C8.37909 19.4645 9.25366 19.8361 10.1899 20.0459L10.625 23H13.375L13.8101 20.0459C14.7463 19.8361 15.6209 19.4645 16.407 18.9663L18.8052 20.7495L20.7495 18.8052L18.9663 16.407C19.4645 15.6209 19.8361 14.7463 20.0459 13.8101L23 13.375V10.625L20.0459 10.1899C19.8361 9.25366 19.4645 8.37909 18.9663 7.59302L20.7495 5.19482L18.8052 3.25049L16.407 5.03369C15.6209 4.53548 14.7463 4.16393 13.8101 3.9541L13.375 1H10.625ZM12 9.25C13.5194 9.25 14.75 10.4806 14.75 12C14.75 13.5194 13.5194 14.75 12 14.75C10.4806 14.75 9.25 13.5194 9.25 12C9.25 10.4806 10.4806 9.25 12 9.25Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinejoin="round"
    />
  </svg>
);
