import { SerializedError } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { Route, useLocation, RouteProps, Redirect } from 'react-router-dom';
import { Routes } from 'constants/';
import { useAppDispatch, useAppSelector } from 'store/';
import { useRefreshMutation } from 'store/api';
import { getAuthStore, setIsLoggedIn } from 'store/slices';
import { useAuthRoute, withAuthRouteContext } from './AuthRouteContext';

export type ProtectedRouteProps = Omit<RouteProps, 'location'>;

let isRefreshing = false;

const AuthRouteInner: React.FC<ProtectedRouteProps> = ({
  component: Component,
  path,
  render,
  children,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const { isLoggedIn } = useAppSelector(getAuthStore);
  const { isAuthComplete } = useAuthRoute();

  const [refresh, { isLoading }] = useRefreshMutation();

  useEffect(() => {
    if (isLoggedIn || isAuthComplete) {
      return;
    }

    if (!isLoggedIn && !isRefreshing) {
      isRefreshing = true;
      refresh()
        .then((result) => {
          if ((result as { error: SerializedError }).error) {
            return;
          }

          dispatch(setIsLoggedIn(true));
        })
        .finally(() => {
          isRefreshing = false;
        });
    }
  }, [
    isLoggedIn,
    location.pathname,
    location.search,
    isRefreshing,
    isAuthComplete,
  ]);

  const renderComponent = (props: any) => {
    if (!isLoggedIn && !isLoading) {
      if (render) {
        return render(props);
      }

      if (Component) {
        return (<Component {...props} />) as any;
      }
    }

    if (isLoading) {
      return null;
    }

    return <Redirect to={Routes.Dashboard} />;
  };

  if (children) {
    return (
      <Route path={path} {...rest}>
        {!isLoggedIn && !isLoading && children}
      </Route>
    );
  }

  return <Route path={path} render={renderComponent} {...rest} />;
};

export const AuthRoute = withAuthRouteContext(AuthRouteInner);
