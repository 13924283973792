import { SVGProps } from 'react';

export const CheckGreen = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_5735_102965)">
      <rect width="16" height="16" rx="4" fill="white" />
      <rect width="16" height="16" rx="4" fill="#239E6A" />
      <path
        d="M7.10497 12.6041L7.08735 12.6217L2.6875 8.22185L4.12018 6.78917L7.10502 9.77402L11.879 5L13.3117 6.43268L7.12264 12.6218L7.10497 12.6041Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_5735_102965">
        <rect width="16" height="16" rx="4" fill="white" />
      </clipPath>
    </defs>
  </svg>
);
