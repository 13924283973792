import { APIRoutes } from 'constants/';
import { UserState } from 'types/';
import { OnboardingTags, UserTags, baseApi } from './baseApi';

export interface UpdateUserStatePayload {
  context: 'onboard_service' | 'onboard_surveys';
  action: 'POPUP_SHOWN';
}

export interface AdvenceUserStatePayload {
  context: 'onboard_service' | 'onboard_surveys';
}

export const onboardingApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUserState: build.query<UserState, void>({
      query: () => ({
        url: APIRoutes.OnboardingState,
        method: 'GET',
      }),
      providesTags: [OnboardingTags.UserState],
    }),
    updateUserState: build.mutation<void, UpdateUserStatePayload>({
      query: (body) => ({
        url: APIRoutes.OnboardingState,
        method: 'POST',
        body,
      }),
      invalidatesTags: [OnboardingTags.UserState],
    }),
    resetUserState: build.mutation<void, void>({
      query: () => ({
        url: APIRoutes.TEMP_ResetOnboardingState,
        method: 'POST',
      }),
      invalidatesTags: [OnboardingTags.UserState],
    }),
    advanceUserState: build.mutation<void, AdvenceUserStatePayload>({
      query: (body) => ({
        url: APIRoutes.TEMP_AdvanceOnboardingState,
        method: 'POST',
        body,
      }),
      invalidatesTags: [OnboardingTags.UserState, UserTags.UserBasic],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetUserStateQuery,
  useUpdateUserStateMutation,
  useResetUserStateMutation,
  useAdvanceUserStateMutation,
  useLazyGetUserStateQuery,
} = onboardingApi;
