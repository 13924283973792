import React from 'react';
import { t } from '@lingui/macro';
import { Close } from 'assets/';
import { ModalProps as AntdModalProps } from 'antd';
import { Button } from 'components/Button';
import {
  StyledModal,
  Description,
  FooterContainer,
  Title,
  TitleContainer,
  TitleWrapper,
} from './styled';

export interface ModalProps extends Omit<AntdModalProps, 'open' | 'title'> {
  title: React.ReactNode;
  subTitle?: React.ReactNode;
  isVisible?: boolean;
  showOkButton?: boolean;
  showCancelButton?: boolean;
  showTitle?: boolean;
  footer?: React.ReactNode;
  actionsVertical?: boolean;
  justifyActions?: React.CSSProperties['justifyContent'];
}

export const Modal = ({
  children,
  title,
  subTitle,
  onCancel,
  onOk,
  isVisible,
  okText = t`Confirm`,
  cancelText = t`Cancel`,
  showOkButton = true,
  showCancelButton = true,
  footer,
  actionsVertical,
  justifyActions,
  ...props
}: ModalProps) => {
  return (
    <StyledModal
      title={
        title && (
          <TitleContainer>
            <TitleWrapper>
              <Title>{title}</Title>
              {subTitle && <Description>{subTitle}</Description>}
            </TitleWrapper>
            <Button
              icon={<Close />}
              type="ghost"
              shape="circle"
              onClick={onCancel}
            />
          </TitleContainer>
        )
      }
      footer={
        footer !== null && (
          <FooterContainer
            actionsVertical={actionsVertical}
            justifyActions={justifyActions}
          >
            {showCancelButton && (
              <Button block type="secondary" onClick={onCancel}>
                {cancelText}
              </Button>
            )}
            {showOkButton && (
              <Button block type="primary" onClick={onOk}>
                {okText}
              </Button>
            )}
            {footer}
          </FooterContainer>
        )
      }
      open={isVisible}
      onCancel={onCancel}
      closable={false}
      maskClosable
      {...props}
    >
      {children}
    </StyledModal>
  );
};
