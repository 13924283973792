import { t, Trans } from '@lingui/macro';
import { SerializedError } from '@reduxjs/toolkit';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import PaypalLogo from 'assets/Paypal.png';
import {
  openGenericErrorNotification,
  openSuccessNotification,
} from 'helpers/';
import { useHandleQueryError } from 'hooks/';
import { PayoutMethod, PayoutMethodInfo, PayoutUnit } from 'types/';
import {
  useCreatePayoutMutation,
  useCurrentUserFullInfoQuery,
  useSupportedPayoutMethodsQuery,
} from 'store/api';
import { log } from 'utils/logService';
import { EventIds } from 'constants/';
import { Form } from 'components/Form';
import { Modal } from 'components/Modal';
import { parseAmount } from 'components/CurrencyInput';
import { usePayOutModal } from './PayOutModalContext';
import {
  PaymentMethod,
  Image,
  Description,
  StyledInput,
  FormLabel,
} from './styled';

interface FormState {
  amount: string | number | null;
}

const parseDisplayAmount = (value: string | number | null) =>
  value ? Number(value.toString().replace('$', '').replace(' ', '')) : 0;
const formatDisplayAmount = (value: string | number | null) =>
  value ? parseDisplayAmount(value.toString()).toFixed(2) : '0.00';

export const PayOutModal = () => {
  const { isVisible, close } = usePayOutModal();

  const { data, endpointName: supportedPayoutMethodsEndpointName } =
    useSupportedPayoutMethodsQuery();
  useHandleQueryError(
    EventIds.PayoutsSupportedMethodsQueryError,
    supportedPayoutMethodsEndpointName
  );

  const { data: userInfoData, endpointName: currentUserFullInfoEndpointName } =
    useCurrentUserFullInfoQuery();
  useHandleQueryError(
    EventIds.UserFullInfoQueryError,
    currentUserFullInfoEndpointName
  );

  const currentBalance =
    userInfoData?.user_performance_summary?.current_balance || 0;

  const [createPayout] = useCreatePayoutMutation();

  const form = useForm<FormState>();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    formState: { errors },
  } = form;

  useEffect(() => {
    if (isVisible) {
      setValue('amount', '');
    }
  }, [isVisible]);

  const amount = watch('amount');

  const { minimum_payout_points, maximum_payout_points } =
    (data && data[PayoutMethod.Paypal]) || ({} as PayoutMethodInfo);
  const { points_per_unit } =
    (data && data[PayoutMethod.Paypal]?.options[0]) || {};

  const onSubmit = handleSubmit(({ amount: unformattedAmount }) => {
    const amount = parseAmount(unformattedAmount);

    if (!amount || !points_per_unit) {
      return;
    }

    const getPoints = () => {
      const points = Math.floor(points_per_unit * amount);

      if ((points / points_per_unit).toFixed(2) === amount.toFixed(2)) {
        return points;
      }

      return points + 1;
    };

    createPayout({
      payout_method: PayoutMethod.Paypal,
      unit_name: PayoutUnit.USD,
      n_units: amount,
      n_points: getPoints(),
    })
      .then((result) => {
        const { error } = result as { error: SerializedError };
        if (error) {
          log.error(
            'Mutation to create a payout has failed',
            EventIds.PayoutsCreateMutationError,
            error
          );
          openGenericErrorNotification();
          return;
        }
        openSuccessNotification(t`Payout initiated`);
        close();
        reset();
      })
      .catch((error) => {
        log.error(
          'Mutation to create a payout has failed',
          EventIds.PayoutsCreateMutationError,
          error
        );
      });
  });

  const handleCancel = () => {
    close();
    reset();
  };

  const handleFocus = () => {
    if (Number(amount) === 0) {
      setValue('amount', null);
    }
  };

  const handleBlur = () => {
    if (!amount) {
      setValue('amount', '');
    }
  };

  const availableUnits =
    points_per_unit && formatDisplayAmount(currentBalance / points_per_unit);

  const setMaxPayout = () => {
    if (availableUnits) {
      setValue(
        'amount',
        parseAmount(availableUnits) > maximum_payout_points / points_per_unit
          ? formatDisplayAmount(maximum_payout_points / points_per_unit)
          : availableUnits
      );
    }
  };

  return (
    <Modal
      title={t`Payout`}
      okText={t`Redeem`}
      isVisible={isVisible}
      onOk={onSubmit}
      onCancel={handleCancel}
      destroyOnClose
      centered
    >
      <PaymentMethod>
        <Image src={PaypalLogo} alt={t`PayPal`} />
      </PaymentMethod>
      <Description>
        <Trans>Please enter amount you want to redeem</Trans>
      </Description>
      {points_per_unit && (
        <Form form={form} onSubmit={onSubmit} id="payout_form" gap={0}>
          <FormLabel htmlFor="amount" onClick={setMaxPayout}>
            <Trans>Amount available: ${availableUnits}</Trans>
          </FormLabel>
          <Form.Item name="amount">
            <StyledInput
              prefix="$"
              placeholder="$0.00"
              decimalScale={2}
              $isError={!!errors.amount}
              onFocus={handleFocus}
              value={amount}
              {...register('amount', {
                required: t`This field is required`,
                onBlur: handleBlur,
                validate: (unformattedValue) => {
                  const value = parseAmount(unformattedValue);

                  if (!value) {
                    return t`This field is required`;
                  }
                  if (value < minimum_payout_points / points_per_unit) {
                    const minimumPayoutAmount = formatDisplayAmount(
                      minimum_payout_points / points_per_unit
                    );
                    return t`The minimum payout amount is $${minimumPayoutAmount}`;
                  }
                  if (value > currentBalance / points_per_unit) {
                    return t`The amount exceeds your balance`;
                  }
                  if (value > maximum_payout_points / points_per_unit) {
                    return t`The amount exceeds maximum payout limit`;
                  }
                  return true;
                },
              })}
            />
          </Form.Item>
        </Form>
      )}
    </Modal>
  );
};
