import { SVGProps } from 'react';

export const Mail = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M15.4286 2.28613H0.571429C0.255357 2.28613 0 2.54149 0 2.85756V14.2861C0 14.6022 0.255357 14.8576 0.571429 14.8576H15.4286C15.7446 14.8576 16 14.6022 16 14.2861V2.85756C16 2.54149 15.7446 2.28613 15.4286 2.28613ZM14.7143 4.2647V13.5718H1.28571V4.2647L0.792857 3.88078L1.49464 2.97899L2.25893 3.57363H13.7429L14.5071 2.97899L15.2089 3.88078L14.7143 4.2647V4.2647ZM13.7429 3.57185L8 8.03613L2.25714 3.57185L1.49286 2.9772L0.791072 3.87899L1.28393 4.26292L7.38393 9.00578C7.55936 9.14206 7.77518 9.21605 7.99732 9.21605C8.21947 9.21605 8.43529 9.14206 8.61071 9.00578L14.7143 4.2647L15.2071 3.88078L14.5054 2.97899L13.7429 3.57185Z"
      fill="#EB4B53"
    />
  </svg>
);
