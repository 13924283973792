import { SVGProps } from 'react';

export const MenuArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={6}
    height={8}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.63965 4L1.05417 1.41451C0.663644 1.02399 0.584498 0.469966 0.877391 0.177073C1.17028 -0.115821 1.7243 -0.0366749 2.11483 0.353849L4.94326 3.18228C5.18655 3.42557 5.30899 3.73231 5.29591 3.99984C5.30909 4.26744 5.18665 4.57432 4.94327 4.8177L2.11484 7.64613C1.72432 8.03665 1.1703 8.1158 0.877405 7.82291C0.584512 7.53001 0.663657 6.97599 1.05418 6.58547L3.63965 4Z"
      fill="currentColor"
    />
  </svg>
);
