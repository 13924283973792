import { t } from '@lingui/macro';
import { Modal } from 'components/Modal';
import { useContactUsSuccessModal } from './ContactUsSuccessModalContext';

export const ContactUsSuccessModal = () => {
  const { isVisible, close } = useContactUsSuccessModal();

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title={t`Thank You!`}
      subTitle={t`Thank you for contacting us, we will review your message and get back to you.`}
      isVisible={isVisible}
      onOk={handleCancel}
      onCancel={handleCancel}
      showOkButton={false}
      cancelText={t`Close`}
      centered
    />
  );
};
