import { SVGProps } from 'react';

export const ErrorIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.94471 1.551C8.51156 0.816335 7.48844 0.816334 7.05529 1.551L0.166301 13.2354C-0.28851 14.0068 0.243214 15 1.11101 15H14.889C15.7568 15 16.2885 14.0068 15.8337 13.2354L8.94471 1.551ZM8.63715 11.1891H7.40332V12.4229H8.63715V11.1891ZM8.63715 5.65674H7.40332V9.95525H8.63715V5.65674Z"
      fill="currentColor"
    />
  </svg>
);
