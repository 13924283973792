import React, { useEffect, useMemo } from 'react';
import { t } from '@lingui/macro';
import { UseFormReturn } from 'react-hook-form';
import { contactUsSubjects } from 'constants/';
import { Form } from 'components/Form';
import { Select } from 'components/Select';
import { ContactUsFormData } from '../hooks';
import { StyledTextArea } from './styled';

export const ContactUsFormItems = ({
  email,
  form,
}: {
  email?: string;
  form: UseFormReturn<ContactUsFormData, any>;
}) => {
  const {
    register,
    clearErrors,
    watch,
    setValue,
    formState: { errors },
  } = form;

  const subjectOptions = useMemo(
    () => contactUsSubjects.map((item) => ({ value: item, label: item })),
    [contactUsSubjects]
  );

  const subject = watch('subject');
  const message = watch('message');

  useEffect(() => {
    register('message', {
      required: t`Please write a more detailed message!`,
      minLength: {
        value: 9,
        message: t`Please write a more detailed message!`,
      },
      maxLength: {
        value: 500,
        message: t`Your message can't be longer than 500 characters`,
      },
    });
    register('subject', {
      required: t`Subject is required`,
    });
  }, [register]);

  useEffect(() => {
    if (message?.length > 9) {
      clearErrors('message');
    }
  }, [message]);

  useEffect(() => {
    if (subject) {
      clearErrors('subject');
    }
  }, [subject]);

  const handleSubjectChange = (value: string) => {
    setValue('subject', value);
  };

  const handleMessageChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = e.target;
    setValue('message', value);
  };

  return (
    <>
      <Form.Item label={t`Your email`} name="email">
        <Form.Input disabled defaultValue={email} />
      </Form.Item>
      <Form.Item label={t`Subject`} name="subject">
        <Select
          showSearch
          placeholder={t`Select`}
          options={subjectOptions}
          onChange={handleSubjectChange}
          status={errors.subject ? 'error' : ''}
          value={subject}
        />
      </Form.Item>
      <Form.Item label={t`Your Message`} name="message">
        <StyledTextArea
          placeholder={t`What seems to be the problem?`}
          autoSize={{ minRows: 2, maxRows: 14 }}
          onChange={handleMessageChange}
          maxLength={500}
          showCount
          status={errors.message && 'error'}
          value={message}
        />
      </Form.Item>
    </>
  );
};
