import { Typography } from 'antd';
import { breakpoint, color } from 'helpers';
import styled from 'styled-components';

const { Text } = Typography;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  background-color: ${color('neutral.100')};
  height: 100vh;
  padding: 144px 16px 16px;

  ${breakpoint('md')} {
    justify-content: center;
    background-color: ${color('neutral.0')};
    padding: 16px;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
  max-width: 688px;
  background-color: ${color('neutral.0')};
  text-align: center;
  padding: 105px 140px;
  border-radius: 6px;

  ${breakpoint('md')} {
    justify-content: center;
    max-width: 100%;
    height: 100%;
    padding: 16px;
  }
`;

export const Title = styled(Text)`
  && {
    color: ${color('neutral.1000')};
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
  }
`;

export const Description = styled(Text)`
  && {
    color: ${color('neutral.1000')};
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`;
