import React, { useContext, useMemo, useState } from 'react';

export interface AuthRouteContextState {
  isAuthComplete: boolean;
  setIsAuthComplete: React.Dispatch<React.SetStateAction<boolean>>;
}

const defaultState: AuthRouteContextState = {
  isAuthComplete: true,
  setIsAuthComplete: () => undefined,
};

export const AuthRouteContext = React.createContext(defaultState);

export const useAuthRoute = () => useContext(AuthRouteContext);

export const AuthRouteContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isAuthComplete, setIsAuthComplete] = useState(
    defaultState.isAuthComplete
  );

  const state = useMemo(
    () => ({
      isAuthComplete,
      setIsAuthComplete,
    }),
    [isAuthComplete]
  );

  return (
    <AuthRouteContext.Provider value={state}>
      {children}
    </AuthRouteContext.Provider>
  );
};

export const withAuthRouteContext = <P extends {}>(
  Component: React.ComponentType<P>
) => {
  return (props: P) => {
    return (
      <AuthRouteContextProvider>
        <Component {...props} />
      </AuthRouteContextProvider>
    );
  };
};
