import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import { color } from 'helpers/';
import { CashIcon } from 'assets/';

const { Text } = Typography;

export const StepsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

export const StepsItem = styled.div`
  display: flex;
  gap: 8px;
`;

export const StepsItemText = styled(Text)<{ $isCompleted?: boolean }>`
  && {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: ${color('neutral.800')};
    ${({ $isCompleted }) =>
      $isCompleted &&
      css`
        text-decoration: line-through;
        color: ${color('neutral.600')};
      `}
  }
`;

export const CoinIcon = styled(CashIcon)`
  margin-top: 2px;
`;
