import { Link, useLocation } from 'react-router-dom';
import { Trans, t } from '@lingui/macro';
import { EventIds, Routes } from 'constants/';
import { useHandleQueryError } from 'hooks/';
import { useCurrentUserQuery } from 'store/api';
import { Button } from 'components/Button';
import { usePayOutModal } from 'components/PayOutModal';
import { Container, Points, StyledLink } from './styled';

const RedeemButton = ({ currentBalance }: { currentBalance?: number }) => {
  const { open: openPayOutModal } = usePayOutModal();
  const location = useLocation();

  if (!currentBalance) {
    return (
      <Button disabled type="secondary">
        <Trans>Redeem</Trans>
      </Button>
    );
  }

  if (location.pathname.includes(Routes.Payouts)) {
    return (
      <Button type="secondary" onClick={openPayOutModal}>
        <Trans>Redeem</Trans>
      </Button>
    );
  }

  return (
    <Link to={Routes.Payouts} component={StyledLink}>
      <Button type="secondary">
        <Trans>Redeem</Trans>
      </Button>
    </Link>
  );
};

export const UserPoints = () => {
  const { data, isLoading, endpointName } = useCurrentUserQuery();

  useHandleQueryError(EventIds.UserBasicInfoQueryError, endpointName);

  const currentBalance = data?.user_performance_summary.current_balance;

  return (
    <Container>
      <Points $isDisabled={!currentBalance}>
        {!isLoading && t`${currentBalance} pts`}
      </Points>
      <RedeemButton currentBalance={currentBalance} />
    </Container>
  );
};
