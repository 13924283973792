import { t } from '@lingui/macro';
import { notification } from 'antd';
import { SuccessIcon, WarningIcon, ErrorIcon, InfoIcon } from 'assets/icons';

export const openSuccessNotification = (
  message: string,
  description?: string,
  duration?: number
) => {
  notification.open({
    type: 'success',
    message,
    description,
    placement: 'top',
    className: `success-notification ${
      !description ? 'notification-without-description' : ''
    }`,
    duration,
    icon: <SuccessIcon />,
  });
};

export const openWarningNotification = (
  message: string,
  description?: string,
  duration?: number
) => {
  notification.open({
    type: 'warning',
    message,
    description,
    placement: 'top',
    className: `warning-notification ${
      !description ? 'notification-without-description' : ''
    }`,
    duration,
    icon: <WarningIcon />,
  });
};

export const openErrorNotification = (
  message: string,
  description?: string
) => {
  notification.open({
    type: 'error',
    message,
    description,
    placement: 'top',
    className: `error-notification ${
      !description ? 'notification-without-description' : ''
    }`,
    icon: <ErrorIcon />,
  });
};

export const openGenericErrorNotification = () =>
  openErrorNotification(
    t`Uh oh! Something went wrong. Check your internet and try again.`
  );

export const openInfoNotification = (
  message: string,
  description?: string,
  duration?: number
) => {
  notification.open({
    type: 'info',
    message,
    description,
    placement: 'top',
    className: `info-notification ${
      !description ? 'notification-without-description' : ''
    }`,
    duration,
    icon: <InfoIcon />,
  });
};
