import { useState } from 'react';

/**
 * usePagination is a custom hook that manages pagination state for a component with Antd table.
 *
 * @param initialPage - The initial page number, default is 1.
 * @param initialPageSize - The initial page size, default is 50.
 * @returns { pageNum, pageSize, handlePaginationChange } - Current page and page size, along with a function to update them.
 */
export const usePagination = (
  initialPage: number = 1,
  initialPageSize: number = 50
) => {
  const [pageNum, setPageNum] = useState(initialPage);
  const [pageSize, setPageSize] = useState(initialPageSize);

  /**
   * handlePaginationChange updates the page and page size.
   *
   * @param page - The new page number.
   * @param newPageSize - The new page size.
   */
  const handlePaginationChange = (page: number, newPageSize: number) => {
    setPageNum(page);
    setPageSize(newPageSize);
  };

  return { pageNum, pageSize, handlePaginationChange };
};
