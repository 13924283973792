import React, { useContext, useEffect, useMemo, useState } from 'react';
import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import { en } from 'make-plural/plurals';
import { messages as enMessages } from 'locales/en/messages';

export type Locale = 'en';

export const locales: Record<Locale, string> = {
  en: 'English',
};
export const defaultLocale: Locale = 'en';

i18n.loadLocaleData({
  en: { plurals: en },
});
i18n.load(defaultLocale, enMessages);
i18n.activate(defaultLocale);

export const i18nDynamicActivate = async (locale: Locale) => {
  const { messages } = await import(`../../locales/${locale}/messages`);
  i18n.load(locale, messages);
  i18n.activate(locale);
};

export interface LocaleContextState {
  locale: Locale;
  setLocale: React.Dispatch<React.SetStateAction<Locale>>;
}

const defaultState: LocaleContextState = {
  locale: 'en',
  setLocale: () => undefined,
};

export const LocaleContext = React.createContext(defaultState);
export const useLocale = () => useContext(LocaleContext);

export const LocaleProvider = ({ children }: { children: React.ReactNode }) => {
  const [locale, setLocale] = useState(defaultState.locale);

  useEffect(() => {
    if (locale !== defaultLocale) {
      i18nDynamicActivate(locale);
    }
  }, [locale]);

  const state = useMemo(
    () => ({
      locale,
      setLocale,
    }),
    [locale]
  );

  return (
    <I18nProvider i18n={i18n}>
      <LocaleContext.Provider value={state}>{children}</LocaleContext.Provider>
    </I18nProvider>
  );
};
