import { Typography } from 'antd';
import { color } from 'helpers';
import styled from 'styled-components';

const { Text } = Typography;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 8px;
  gap: 4px;
  background: ${color('neutral.200')};
  border-radius: 6px;
  span {
    display: flex;
  }
  width: 100%;
`;

export const Label = styled(Text)`
  && {
    display: block;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: justify;
    color: ${color('neutral.800')};

    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
