import { SVGProps } from 'react';

export const Payouts = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_3072_262913" fill="white">
      <rect x="2" y="3" width="20" height="7" rx="1" />
    </mask>
    <rect
      x="2"
      y="3"
      width="20"
      height="7"
      rx="1"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-1-inside-1_3072_262913)"
    />
    <mask id="path-2-inside-2_3072_262913" fill="white">
      <rect x="6" y="6" width="12" height="16" rx="1" />
    </mask>
    <rect
      x="6"
      y="6"
      width="12"
      height="16"
      rx="1"
      fill="white"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-2-inside-2_3072_262913)"
    />
    <circle
      cx="12"
      cy="12"
      r="2.25"
      fill="white"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M10 21C10 19.3431 8.65685 18 7 18"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M14 21C14 19.3431 15.3431 18 17 18"
      stroke="currentColor"
      strokeWidth="1.5"
    />
  </svg>
);
