import { SVGProps } from 'react';

export const VisibilityOn = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.53071 7.38421C2.05961 6.6642 3.10216 5.47023 4.65836 4.67759L6.04518 6.06441C5.553 6.56148 5.24904 7.24527 5.24904 8.00006C5.24904 9.51946 6.48077 10.7512 8.00018 10.7512C8.75496 10.7512 9.43875 10.4472 9.93582 9.95505L11.3162 11.3354C10.391 11.8023 9.28567 12.1267 8.00008 12.1267C4.53226 12.1267 2.3758 9.76622 1.53071 8.61576C1.25799 8.24449 1.25799 7.75548 1.53071 7.38421ZM10.5142 9.11917L12.206 10.811C13.2908 10.0754 14.0453 9.19318 14.4695 8.61576C14.7422 8.24449 14.7422 7.75548 14.4695 7.38421C13.6244 6.23375 11.4679 3.87329 8.00008 3.87329C7.13619 3.87329 6.35368 4.01978 5.65254 4.25756L6.88106 5.48608C7.22296 5.33365 7.60167 5.24893 8.00018 5.24893C9.51958 5.24893 10.7513 6.48065 10.7513 8.00006C10.7513 8.39856 10.6666 8.77727 10.5142 9.11917Z"
      fill="#383B48"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.52055 3.14645C3.32528 2.95118 3.0087 2.95118 2.81344 3.14645C2.61818 3.34171 2.61818 3.65829 2.81344 3.85355L5.62774 6.66786C5.81685 6.39007 6.05706 6.14986 6.33485 5.96075L3.52055 3.14645ZM10.0396 9.66548C9.85047 9.94326 9.61026 10.1835 9.33247 10.3726L12.1468 13.1869C12.342 13.3821 12.6586 13.3821 12.8539 13.1869C13.0491 12.9916 13.0491 12.675 12.8539 12.4798L10.0396 9.66548Z"
      fill="#383B48"
    />
    <path
      d="M9.33366 7.99996C9.33366 8.73634 8.73671 9.33329 8.00033 9.33329C7.26395 9.33329 6.66699 8.73634 6.66699 7.99996C6.66699 7.26358 7.26395 6.66663 8.00033 6.66663C8.73671 6.66663 9.33366 7.26358 9.33366 7.99996Z"
      fill="#383B48"
    />
  </svg>
);
