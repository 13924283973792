import styled from 'styled-components';
import { Input } from 'antd';
import { color } from 'helpers/';

const { TextArea } = Input;

export const StyledTextArea = styled(TextArea)`
  && {
    .ant-input {
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      padding: 11px 12px;
    }
    .ant-input-status-error {
      background-color: ${color('error.100')};
    }
    .ant-input:focus,
    .ant-input:hover {
      border-color: ${color('error.1000')};
    }
  }
`;
