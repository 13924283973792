import { createGlobalStyle } from 'styled-components';
import { color, radius } from 'helpers/';

export const AntdStylesOverride = createGlobalStyle`
  .ant-layout.ant-layout {
    background: ${color('neutral.100')};
  }
  .ant-alert-icon.ant-alert-icon {
    font-size: 28px;
  }
  .ant-alert {
    padding: 16px;
    color: ${color('neutral.1000')};
  }
  .ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) .ant-select-selector {
    background-color: ${color('error.100')};
  }

  .notification-without-description {
    &.ant-notification-notice {
      .ant-notification-notice-message {
        margin-bottom: 0;
      }
    }
  }
  .success-notification {
    background-color: ${color('success')};
    &.ant-notification-notice {
      width: 454px;

      .ant-notification-notice-message,
      .ant-notification-notice-icon,
      .ant-notification-notice-close {
        color: ${color('neutral.0')};
      }
      .ant-notification-notice-description {
        color: ${color('success.200')};
      }
      .ant-notification-notice-icon {
        svg {
          fill: ${color('neutral.0')};
        }
      }
    }
  }
  .error-notification {
    background-color: ${color('error')};
    &.ant-notification-notice {
      width: 454px;

      .ant-notification-notice-message,
      .ant-notification-notice-icon,
      .ant-notification-notice-close {
        color: ${color('neutral.0')};
      }
      .ant-notification-notice-description {
        color: ${color('error.200')};
      }
    }
  }
  .info-notification {
    background-color: ${color('info')};
    &.ant-notification-notice {
      width: 454px;

      .ant-notification-notice-message,
      .ant-notification-notice-icon,
      .ant-notification-notice-close {
        color: ${color('neutral.0')};
      }
      .ant-notification-notice-description {
        color: ${color('info.200')};
      }
    }
  }
  .warning-notification {
    background-color: ${color('warning')};
    &.ant-notification-notice {
      width: 454px;

      .ant-notification-notice-message,
      .ant-notification-notice-icon,
      .ant-notification-notice-close {
        color: ${color('neutral.0')};
      }
      .ant-notification-notice-description {
        color: ${color('warning.200')};
      }
    }
  }
  .ant-btn {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 7px 13px;
    &&.ant-btn-primary.ant-btn-primary {
      &:disabled {
        background-color: ${color('neutral.200')};
        color: ${color('neutral.400')};
      }
    }
  }
  a.secondary-button,button.secondary-button {
    text-decoration: none;
    background-color: transparent;
    color: ${color('neutral.800')};
    border-color: ${color('neutral.400')};
    &:hover {
      border-color: ${color('primary.500')};
    }
    &:disabled {
      background-color: transparent;
      color: ${color('neutral.400')};
      border-color: ${color('neutral.200')};
    }
  }
  a.secondary-black-button.secondary-black-button,button.secondary-black-button.secondary-black-button {
    text-decoration: none;
    background-color: transparent;
    color: ${color('neutral.0')};
    border-color: ${color('neutral.400')};
    &:hover, &:active {
      border-color: ${color('neutral.1000')};
      background-color: ${color('neutral.1000')};
      filter: drop-shadow(0px 1px 2px rgba(11, 12, 14, 0.3)) drop-shadow(0px 3px 6px rgba(11, 12, 14, 0.2));
    }
    &:disabled {
      background-color: transparent;
      color: ${color('neutral.400')};
      border-color: ${color('neutral.200')};
    }
  }
  .ant-btn.ant-popover-open {
    font-size: initial;
  }
  .ant-btn-link {
    width: min-content;
    padding: 0;
    height: auto;
    color: ${color('neutral.800')};
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;

    span {
      text-decoration: underline;
    }
  }
  button.link-red-button {
    color: ${color('primary.600')};
    &.ant-btn.ant-popover-open {
      font-size: inherit;
    }
  }  
  .card-tabs {
    .ant-tabs-nav {
      margin-bottom: 0;
      &::before {
        display: none;
      }
    }
    .ant-tabs-tabpane {
      padding: 16px;
      background-color: ${color('neutral.0')};
    }
    .ant-tabs-tab.ant-tabs-tab {
      justify-content: center;
      border: 0;
      .ant-tabs-tab-btn {
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: ${color('neutral.800')};
        text-shadow: none;
        &:active {
          border: 0;
        }
      }
      .ant-tabs-tab-active {
        color: ${color('neutral.1000')};
      }
      &:not(.ant-tabs-tab-active) {
        border: 0;
        background-color: transparent;
      }
    }
    &.spread-tab-nav {
      .ant-tabs-nav-list.ant-tabs-nav-list {
        display: grid;
        grid-auto-flow: column;
        grid-auto-columns: 1fr;
        width: 100%;
      }
    }
  }
  .ant-switch {
    &.ant-switch-checked {
      background: ${color('success.500')};
    }

    &.ant-switch-checked:hover:not(.ant-switch-disabled) {
      background: ${color('success.400')};
    }
  }
  .table {
    overflow-y: hidden;
    th,td {
      &.ant-table-cell.ant-table-cell {
        &:first-of-type {
          padding-left: 24px;
        }
        &:last-of-type {
          padding-right: 24px;
        }
      }
    }
    th.ant-table-cell.ant-table-cell {
      background: transparent;
      font-weight: 500;
      font-size: 12px;
      line-height: 16px;
      letter-spacing: 0.05em;
      text-transform: uppercase;
      color: ${color('neutral.400')};
      border-color: ${color('neutral.200')};
      
      ::before {
        display: none;
      }
    }
    td.ant-table-cell.ant-table-cell {
      border-color: ${color('neutral.200')};
    }
  }
  .ant-pagination.ant-pagination,
  .ant-pagination.ant-table-pagination.ant-table-pagination {
    row-gap: 4px;
    margin: 16px 24px 0;
    li {
      min-width: 32px;
      height: 32px;
      line-height: 32px;
      margin-inline-end: 4px;

      &.ant-pagination-prev, &.ant-pagination-next {
        button {
          border: 1px solid ${color('neutral.400')};
          border-radius: ${radius('base')};
          display: flex;
          justify-content: center;
          align-items: center;
        }
        &.ant-pagination-disabled {
          button {
            border: 0;
            background: ${color('neutral.200')};
          }
        }
      }

      &.ant-pagination-item {
        border: 1px solid ${color('neutral.200')};
        border-radius: ${radius('base')};
        font-weight: 500;
        font-size: 16px;
        a {
          text-decoration: none;
        }
        &.ant-pagination-item-active {
          a {
            color: ${color('neutral.0')};
          }
          border: 0;
          background: ${color('neutral.800')};
        }
      }

      .ant-select-selector.ant-select-selector {
        height: 32px;

        input.ant-select-selection-search-input {
          height: 30px;
        }

        .ant-select-selection-item {
          line-height: 30px;
        }
      }
    }
  }
  .ant-popover.ant-popover {
    max-width: 236px;

    .ant-popconfirm-buttons {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    .ant-popover-inner {
      background: ${color('neutral.1000')};
      
      .ant-popconfirm-inner-content .ant-popconfirm-message {
        color: ${color('neutral.0')};
      }
    }
    .ant-popover-content {
      .ant-popover-arrow::before {
        background: ${color('neutral.1000')};
      }
    }
  }
  .ant-drawer-content-wrapper {
    border-radius: 24px 24px 0px 0px;
      .ant-drawer-content {
        border-radius: 24px 24px 0px 0px;
      }
  }
  .ant-modal .ant-modal-footer .ant-btn {
    justify-content: center;
  }
  .ant-tooltip.ant-tooltip{
    max-width: 300px;
    .ant-tooltip-inner {
      padding: 8px 10px; 
    }
  }
  .ant-select-dropdown.ant-select-dropdown {
    background-color: ${color('neutral.0')};
  }
`;
