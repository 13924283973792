import React from 'react';
import { Typography, Modal } from 'antd';
import styled, { css } from 'styled-components';
import { color, radius } from 'helpers/';

const { Title: AntdTitle, Text } = Typography;

export const StyledModal = styled(Modal)<{ width?: string | number }>`
  && {
    ${({ width }) =>
      !width &&
      css`
        max-width: 340px;
      `}
  }

  .ant-modal-content {
    background-color: ${color('neutral.0')};
    border-radius: ${radius('base')};
  }

  .ant-modal-header {
    background-color: ${color('neutral.0')};
  }
`;

export const TitleContainer = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content;
  gap: 12px;
  margin-bottom: 24px;

  && {
    button {
      margin-top: 4px;
      padding: 0;
      font-size: 12px;
      min-width: 16px;
      width: 16px;
      height: 16px;
      color: rgba(0, 0, 0, 0.45);
    }
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const Title = styled(AntdTitle)`
  && {
    font-weight: 700;
    font-size: 18px;
    line-height: 24px;
    color: ${color('neutral.800')};
    margin-bottom: 0;
  }
`;

export const Description = styled(Text)`
  && {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: ${color('neutral.700')};
  }
`;

export const FooterContainer = styled.div<{
  actionsVertical?: boolean;
  justifyActions?: React.CSSProperties['justifyContent'];
}>`
  display: flex;
  gap: 8px;
  padding-top: 12px;
  justify-content: center;
  ${({ justifyActions }) =>
    justifyActions &&
    css`
      justify-content: ${justifyActions};
    `}
  flex-direction: row;
  ${({ actionsVertical }) =>
    actionsVertical &&
    css`
      flex-direction: column;
    `}

  button {
    margin-inline-start: 0 !important;
  }
`;
