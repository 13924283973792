import { forwardRef } from 'react';
import {
  NumericFormat,
  NumericFormatProps,
  useNumericFormat,
} from 'react-number-format';

export const parseAmount = (value: string | number | null, unit = '$') => {
  if (!value) {
    return 0;
  }

  const clearString = value.toString().replace(unit, '').replace(' ', '');

  if (!clearString) {
    return 0;
  }

  return Number.parseFloat(clearString);
};

export const CurrencyInput = forwardRef<
  HTMLInputElement,
  Partial<NumericFormatProps>
>(({ ...props }, ref) => {
  const { ...rest } = useNumericFormat({
    ...props,
  });

  return <NumericFormat {...props} {...rest} getInputRef={ref} />;
});
