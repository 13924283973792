import { isRejectedWithValue } from '@reduxjs/toolkit';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { log } from 'utils/logService';
import { EventIds } from 'constants/';
import { ErrorState, setIsErrorVisible } from '../slices';

export const ErrorHandlerMiddleware: Middleware =
  ({ getState, dispatch }: MiddlewareAPI) =>
  (next) =>
  (action) => {
    if (!isRejectedWithValue(action)) {
      return next(action);
    }

    const state = getState();
    const { endpointHandlers } = state.errorStore as ErrorState;

    const endpoints = Object.keys(endpointHandlers).filter(
      (endpoint) => !!endpointHandlers[endpoint]
    );

    const { endpointName } = action.meta.arg;

    if (!endpoints.includes(endpointName)) {
      return next(action);
    }

    const eventIds = Object.keys(endpointHandlers[endpointName] || {}).filter(
      (eventId) => !!endpointHandlers[endpointName][Number(eventId) as EventIds]
    );

    if (!eventIds.length) {
      return next(action);
    }

    dispatch(setIsErrorVisible(true));

    eventIds.forEach((eventId) => {
      log.error(
        'Query has failed',
        Number(eventId) as EventIds,
        action.payload
      );
    });

    return next(action);
  };
