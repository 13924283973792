import { t, Trans } from '@lingui/macro';
import { Switch } from 'antd';
import { Routes } from 'constants/';
import { removeItemFromLocalStorage } from 'helpers/';
import { getAuthStore } from 'store/slices';
import { useAppSelector } from 'store/';
import { useBreakpoint } from 'hooks/';
import { Modal } from 'components/Modal';
import { AnalyticsLink } from 'components/AnalyticsLink';
import { Button } from 'components/Button';
import { useCookieSettingsModal } from './CookieSettingsModalContext';
import {
  LinkText,
  StyledLink,
  Container,
  Group,
  GroupTitle,
  GroupItem,
  GroupItemHeader,
  GroupItemTitle,
  GroupItemDescription,
  GroupItemKey,
} from './styled';

export enum CookieSettings {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

export const CookieSettingsModal = () => {
  const { isVisible, close, preferences, setPreferences, savePreferences } =
    useCookieSettingsModal();
  const { isLoggedIn } = useAppSelector(getAuthStore);
  const isMobile = useBreakpoint('md');

  const handleApply = () => {
    if (isLoggedIn) {
      savePreferences();
    }

    if (!preferences.cookie_waseversignedin) {
      removeItemFromLocalStorage('wasEverSignedIn');
    }

    close();
  };

  const handleCancel = () => {
    close();
  };

  const handleOptionChange = (checked: boolean) =>
    setPreferences({ cookie_waseversignedin: checked });

  return (
    <Modal
      title={t`Cookie Settings`}
      subTitle={
        <Trans>
          We want to be transparent about how we and our partners recognize you
          and how we use the information we collect, so you can best exercise
          control over your personal data. For more information, please see our{' '}
          <AnalyticsLink
            to={Routes.PrivacyPolicy}
            component={StyledLink}
            source="cookie-settings-modal"
            target="_blank"
          >
            <LinkText>Privacy Policy</LinkText>
          </AnalyticsLink>
        </Trans>
      }
      isVisible={isVisible}
      onOk={handleApply}
      onCancel={handleCancel}
      centered
      footer={[
        <Button
          type="primary"
          onClick={handleApply}
          key="apply"
          block={isMobile}
        >
          <Trans>Apply Changes</Trans>
        </Button>,
      ]}
      width={757}
      showCancelButton={false}
      showOkButton={false}
      justifyActions="flex-end"
    >
      <Container>
        <Group>
          <GroupTitle>
            <Trans>Essential:</Trans>
          </GroupTitle>
          <GroupItem>
            <GroupItemHeader>
              <GroupItemTitle>
                <Trans>Log-In Session ID</Trans>
              </GroupItemTitle>
              <GroupItemDescription>
                <Trans>
                  Authenticates that you have logged in to your account
                </Trans>
              </GroupItemDescription>
            </GroupItemHeader>
            <GroupItemKey>sessionid</GroupItemKey>
            <Switch defaultChecked disabled />
          </GroupItem>
          <GroupItem>
            <GroupItemHeader>
              <GroupItemTitle>
                <Trans>CSRF Token</Trans>
              </GroupItemTitle>
              <GroupItemDescription>
                <Trans>
                  Secures your account against CSRF and prevents other fraud
                </Trans>
              </GroupItemDescription>
            </GroupItemHeader>
            <GroupItemKey>csrftoken</GroupItemKey>
            <Switch defaultChecked disabled />
          </GroupItem>
        </Group>
        <Group>
          <GroupTitle>
            <Trans>Functional:</Trans>
          </GroupTitle>
          <GroupItem>
            <GroupItemHeader>
              <GroupItemTitle>
                <Trans>Welcome Back Token</Trans>
              </GroupItemTitle>
              <GroupItemDescription>
                <Trans>
                  Recognizes that you are a returning user so we can take you
                  right to the log-in page
                </Trans>
              </GroupItemDescription>
            </GroupItemHeader>
            <GroupItemKey>wasEverSignedIn</GroupItemKey>
            <Switch
              defaultChecked={preferences.cookie_waseversignedin}
              onChange={handleOptionChange}
            />
          </GroupItem>
        </Group>
      </Container>
    </Modal>
  );
};
