import { t, Trans } from '@lingui/macro';
import { Link } from 'react-router-dom';
import LogoNeutralHorizontal from 'assets/LogoNeutralHorizontal.png';
import { Routes } from 'constants/';
import { useCookieSettingsModal } from 'components/CookieSettingsModal';
import { Container, Logo, StyledLink, StyledButton } from './styled';

export const Footer = () => {
  const { open } = useCookieSettingsModal();

  return (
    <Container>
      <Logo src={LogoNeutralHorizontal} alt={t`Cherries`} />
      <Link to={Routes.TermsOfUse} component={StyledLink}>
        <StyledButton type="link">
          <Trans>Terms of Use</Trans>
        </StyledButton>
      </Link>
      <Link to={Routes.PrivacyPolicy} component={StyledLink}>
        <StyledButton type="link">
          <Trans>Privacy Policy</Trans>
        </StyledButton>
      </Link>
      <StyledButton type="link" onClick={open}>
        <Trans>Cookie Settings</Trans>
      </StyledButton>
    </Container>
  );
};
