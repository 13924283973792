import { QueryReturnValue } from '@reduxjs/toolkit/dist/query/baseQueryTypes';
import { MaybePromise } from '@reduxjs/toolkit/dist/query/tsHelpers';
import {
  BaseQueryFn,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
  FetchBaseQueryMeta,
} from '@reduxjs/toolkit/query';
import { APIRoutes } from 'constants/';
import { getCSRFToken } from 'helpers/';
import { logout, setIsLoggedIn } from '../slices';

const baseQuery = fetchBaseQuery({
  baseUrl: process.env.REACT_APP_PUBLIC_API_HOST,
  credentials: 'include',
  prepareHeaders: (headers) => {
    headers.set('X-CSRFToken', getCSRFToken());
    return headers;
  },
});

let refreshPromise:
  | MaybePromise<QueryReturnValue<any, FetchBaseQueryError, FetchBaseQueryMeta>>
  | undefined;

export const baseQueryWithReauth: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, api, extraOptions) => {
  let result = await baseQuery(args, api, extraOptions);

  if (
    result.error &&
    [401, 403].includes(result.error.status as number) &&
    ![
      APIRoutes.RefreshTokens,
      APIRoutes.SingUp,
      APIRoutes.LogIn,
      APIRoutes.ResetPasswordInitiate,
      APIRoutes.ResetPasswordSubmit,
      APIRoutes.SupplierLogIn,
      APIRoutes.SupplierSignUp,
    ].includes((args as FetchArgs).url as APIRoutes)
  ) {
    const isPrimaryRefresh = refreshPromise === undefined;

    try {
      refreshPromise =
        refreshPromise ||
        baseQuery(
          {
            url: APIRoutes.RefreshTokens,
            method: 'GET',
          },
          api,
          extraOptions
        );

      const refreshResult: QueryReturnValue<
        any,
        FetchBaseQueryError,
        FetchBaseQueryMeta
      > = await refreshPromise;

      if (!refreshResult.error) {
        if (isPrimaryRefresh) {
          api.dispatch(setIsLoggedIn(true));
        }
        result = await baseQuery(args, api, extraOptions);
      } else {
        document.cookie = document.cookie.replace(
          /csrftoken=\w+/,
          'csrftoken=""'
        );
        api.dispatch(logout());
      }
    } finally {
      refreshPromise = undefined;
    }
  }
  return result;
};
