import { Typography } from 'antd';
import styled, { css } from 'styled-components';
import { color } from 'helpers/';

const { Text } = Typography;

export const Container = styled.div`
  display: flex;
  align-items: center;
  padding: 20px;
  width: 100%;
  justify-content: space-between;
  box-shadow: 0px 8px 24px rgba(197, 207, 214, 0.12);
  border-radius: 12px;
  background: ${color('neutral.0')};
  margin-bottom: 36px;
`;

export const Points = styled(Text)<{ $isDisabled?: boolean }>`
  && {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    color: ${color('neutral.1000')};

    ${({ $isDisabled }) =>
      $isDisabled &&
      css`
        color: ${color('neutral.400')};
      `}
  }
`;

export const StyledLink = styled.a`
  text-decoration: none;
`;
