export enum QuestionType {
  SinglePunch = 'single_punch',
  MultiPunch = 'multi_punch',
  Text = 'text',
  Integer = 'integer',
  Decimal = 'decimal',
  Date = 'date',
  Combination = 'combination',
  Blank = 'blank',
}

interface BaseProfilerQuestionConfig {
  button_text?: string;
}
type HasSinglePunchType<
  T extends QuestionType,
  D extends string
> = T extends QuestionType.SinglePunch ? D | 'radio_button' : D;
type HasMultiPunchType<
  T extends QuestionType,
  D extends string
> = T extends QuestionType.MultiPunch ? D | 'check_box' : D;
type HasPunchType<T extends QuestionType> = T extends
  | QuestionType.SinglePunch
  | QuestionType.MultiPunch
  ? {
      display: HasMultiPunchType<
        T,
        HasSinglePunchType<
          T,
          | 'dropdown'
          | 'searchable_dropdown'
          | 'clickable_block'
          | 'clickable_image'
        >
      >;
      max_columns?: number;
    }
  : {};
type HasTextType<T extends QuestionType> = T extends QuestionType.Text
  ? {
      display: 'single_line' | 'multi_line';
      max_characters: number;
      min_characters: number;
      placeholder?: string;
    }
  : {};
type HasIntegerType<T extends QuestionType> = T extends QuestionType.Integer
  ? {
      min_value: number;
      max_value: number;
      unit?: string;
      placeholder?: string;
    }
  : {};
type HasDecimalType<T extends QuestionType> = T extends QuestionType.Decimal
  ? {
      min_value: number;
      max_value: number;
      max_decimal_places: number;
      unit?: string;
      placeholder?: string;
      display_decimal_places?: number;
    }
  : {};
type HasDateType<T extends QuestionType> = T extends QuestionType.Date
  ? {
      display: 'calendar' | 'freeform';
      /**
       * Date in isoformat YYYY-MM-DD
       */
      min_date: string;
      /**
       * Date in isoformat YYYY-MM-DD
       */
      max_date: string;
    }
  : {};

export type ProfilerQuestionConfig<T extends QuestionType = QuestionType> =
  BaseProfilerQuestionConfig &
    HasPunchType<T> &
    HasTextType<T> &
    HasIntegerType<T> &
    HasDecimalType<T> &
    HasDateType<T>;

export interface ProfilerQuestionOptionOther
  extends ProfilerQuestionConfig<QuestionType.Text> {
  type: 'text';
}

// TODO: update to be based on display field value
export interface ProfilerQuestionOption {
  id: number;
  localization_id: number;
  text: string;
  other?: ProfilerQuestionOptionOther;
  deselect_all_others?: boolean;
  image?: string;
}

export type SimpleAnswer = string | number | number[];
export type Answer = SimpleAnswer | Record<number, SimpleAnswer>;
export interface CombinationAnswer {
  profiler_id: number;
  answer: SimpleAnswer;
  other?: string;
}
export type AnswerValue = SimpleAnswer | CombinationAnswer[] | null;
export interface AnswerData {
  answer: AnswerValue;
  other?: any;
}

interface BaseProfilerQuestion<T extends QuestionType = QuestionType> {
  id: number;
  localization_id: number;
  type: T;
  question: string;
  description?: string;
  splash_image?: string;
  previous_answer?: AnswerData;
}

type HasOptions<T extends QuestionType> = T extends
  | QuestionType.SinglePunch
  | QuestionType.MultiPunch
  ? { options: ProfilerQuestionOption[] }
  : {};

type HasSimpleQuestion<T extends QuestionType> =
  T extends QuestionType.Combination
    ? {
        config?: ProfilerQuestionConfig<T>;
      }
    : {
        config: ProfilerQuestionConfig<T>;
      };

export type SimpleProfilerQuestion<T extends QuestionType = QuestionType> =
  BaseProfilerQuestion<T> & HasSimpleQuestion<T> & HasOptions<T>;

interface ProfilerHeading {
  heading?: string;
}

interface CombinationProfilerRule {
  target_profiler_id: number;
  dependencies: {
    source_profiler_id: number;
    source_answer_ids: number[];
  }[];
}

type HasCombination<T extends QuestionType> = T extends QuestionType.Combination
  ? {
      primary_id_list: number[];
      profilers: SimpleProfilerQuestion<T>[];
      rules?: CombinationProfilerRule[];
    }
  : {};

export type ProfilerQuestion<T extends QuestionType = QuestionType> =
  SimpleProfilerQuestion<T> & HasCombination<T> & ProfilerHeading;

export interface Profiler {
  type: 'PROFILER';
  data: ProfilerQuestion;
}
