import React from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import { FetchBaseQueryError } from '@reduxjs/toolkit/query';
import { useForm, UseFormReturn, UseFormReset } from 'react-hook-form';
import { useContactUsMutation } from 'store/api';
import { log } from 'utils/logService';
import { EventIds } from 'constants/';
import { useContactUsSuccessModal } from '../ContactUsSuccessModal';
import { useContactUsModal } from '../ContactUsModal';

export interface ContactUsFormData {
  subject: string;
  message: string;
}

export const useContactUsForm = (): {
  form: UseFormReturn<ContactUsFormData, any>;
  reset: UseFormReset<ContactUsFormData>;
  onSubmit: (
    e?: React.BaseSyntheticEvent<object, any, any> | undefined
  ) => Promise<void>;
  error?: FetchBaseQueryError | SerializedError;
} => {
  const { close: closeFormModal } = useContactUsModal();
  const { open: openSuccessModal } = useContactUsSuccessModal();

  const form = useForm<ContactUsFormData>();
  const { handleSubmit, resetField, reset } = form;

  const [mutate, { error }] = useContactUsMutation();

  const onSubmit = handleSubmit((data) => {
    mutate(data).then((result) => {
      const { error } = result as { error: SerializedError };

      if (error) {
        log.error(
          'Mutation to submit contact us form failed',
          EventIds.ContactUsMutationError,
          error
        );
        return;
      }

      resetField('message');
      resetField('subject');
      closeFormModal();
      openSuccessModal();
    });
  });

  return {
    form,
    reset,
    onSubmit,
    error,
  };
};
