import { Layout } from 'antd';
import styled, { css } from 'styled-components';
import { navbarHeight, navbarWidth } from 'constants/';
import { breakpoint } from 'helpers/';

export const StyledLayout = styled(Layout)`
  min-height: 100vh;
  max-width: 100vw;
  display: grid;
  grid-template-columns: ${navbarWidth}px 1fr;

  ${breakpoint('lg')} {
    grid-template-columns: initial;
    grid-template-rows: ${navbarHeight}px 1fr;
  }
`;

export const StyledContent = styled(Layout.Content)`
  max-height: 100vh;

  ${breakpoint('lg')} {
    max-height: min-content;
    overflow-y: initial;
    max-width: 100vw;
  }
`;

export const ScrollContainer = styled.div<{
  $noPadding?: boolean;
}>`
  position: relative;
  ${breakpoint('xl', 'min-width')} {
    & > div {
      max-width: 1344px;
      justify-self: center;
      width: 100%;
    }
  }

  height: 100%;
  display: grid;
  grid-template-rows: max-content max-content;
  grid-template-columns: 100%;
  overflow-y: auto;

  ${breakpoint('lg')} {
    overflow-y: initial;
    max-width: 100vw;
  }

  ${({ $noPadding }) =>
    !$noPadding &&
    css`
      padding: 32px;

      ${breakpoint('md')} {
        padding: 16px;
      }
    `}
  padding-bottom: 0;
`;
