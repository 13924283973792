export enum PayoutHandler {
  Paypal = 'PAYPAL',
}

export enum PayoutMethod {
  Paypal = 'PAYPAL_CASH',
}

export enum PayoutType {
  Cash = 'CASH',
}

export enum PayoutUnit {
  USD = 'USD',
}

export enum PayoutStatus {
  Pending = 'PENDING',
  Success = 'SUCCESS',
  Failed = 'FAILED',
  FailedPayPalDisconnected = 'FAILED_PAYPAL_DISCONNECTED',
}

export interface PayoutsHistoryItem {
  payout_method: PayoutMethod;
  payout_handler: PayoutHandler;
  payout_type: PayoutType;
  n_units: number;
  unit_name: PayoutUnit;
  n_points: number;
  datetime_created: string;
  status: PayoutStatus;
}

export interface PayoutMethodInfo {
  handler: PayoutHandler;
  type: PayoutType;
  options: Array<{
    unit_name: PayoutUnit;
    points_per_unit: number;
  }>;
  minimum_payout_points: number;
  maximum_payout_points: number;
}

export type PayoutMethods = Record<PayoutMethod, PayoutMethodInfo>;

export interface PayoutsHistory {
  payouts: PayoutsHistoryItem[];
}

export interface PayoutHandlerInfo {
  handler: PayoutHandler;
  email?: string;
  datetime_connected: string;
}
