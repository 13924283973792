import { SerializedError } from '@reduxjs/toolkit';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { openErrorNotification, openGenericErrorNotification } from 'helpers/';
import { useAppSelector } from 'store/';
import { getAuthStore } from 'store/slices';
import {
  useLazyCurrentUserFullInfoQuery,
  usePreferencesMutation,
} from 'store/api';
import { log } from 'utils/logService';
import { EventIds } from 'constants/';
import { Preferences } from 'types/';

export interface CookieSettingsModalContextState {
  isVisible: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
  isLoading?: boolean;
  preferences: Partial<Preferences>;
  setPreferences: React.Dispatch<React.SetStateAction<Partial<Preferences>>>;
  savePreferences: (errorMessage?: string) => Promise<void>;
}

const defaultState: CookieSettingsModalContextState = {
  isVisible: false,
  open: () => undefined,
  close: () => undefined,
  toggle: () => undefined,
  setPreferences: () => undefined,
  savePreferences: () => Promise.reject(),
  preferences: {
    cookie_waseversignedin: true,
  },
};

export const CookieSettingsModalContext = React.createContext(defaultState);

export const useCookieSettingsModal = () =>
  useContext(CookieSettingsModalContext);

export const CookieSettingsModalContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(defaultState.isVisible);
  const [preferences, setPreferences] = useState(defaultState.preferences);
  const location = useLocation();

  const { isLoggedIn } = useAppSelector(getAuthStore);

  const [fetchUserInfo, { data, isLoading }] =
    useLazyCurrentUserFullInfoQuery();

  const [updatePreferences, { isLoading: isUpdatingUserPreferences }] =
    usePreferencesMutation();

  const open = useCallback(() => setIsVisible(true), []);
  const close = useCallback(() => setIsVisible(false), []);
  const toggle = useCallback(() => setIsVisible((prev) => !prev), []);

  useEffect(() => {
    close();
  }, [location.pathname]);

  useEffect(() => {
    if (isLoggedIn) {
      fetchUserInfo();
    }
  }, [isLoggedIn]);

  useEffect(() => {
    if (data) {
      setPreferences(data.user_preferences);
    }
  }, [data]);

  const savePreferences = useCallback(
    (errorMessage?: string) => {
      return updatePreferences(preferences).then((result) => {
        const { error } = result as { error: SerializedError };
        if (error) {
          log.error(
            'Mutation to update user preferences has failed',
            EventIds.UserPreferencesMutationError,
            error
          );

          if (errorMessage) {
            openErrorNotification(errorMessage);
          } else {
            openGenericErrorNotification();
          }
        }
      });
    },
    [preferences]
  );

  const state = useMemo(
    () => ({
      isVisible,
      open,
      close,
      toggle,
      isLoading: isLoading || isUpdatingUserPreferences,
      preferences,
      setPreferences,
      savePreferences,
    }),
    [isVisible, preferences, isLoading, isUpdatingUserPreferences]
  );

  return (
    <CookieSettingsModalContext.Provider value={state}>
      {children}
    </CookieSettingsModalContext.Provider>
  );
};

export const withCookieSettingsModalContext = <P extends {}>(
  Component: React.ComponentType<P>
) => {
  return (props: P) => {
    return (
      <CookieSettingsModalContextProvider>
        <Component {...props} />
      </CookieSettingsModalContextProvider>
    );
  };
};
