import { useEffect } from 'react';
import { useLocation } from 'react-router';

export const useStoreInSearchParams = <
  Config extends Record<string, string | number | undefined>
>(
  config: Config
): Config => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    Object.keys(config).forEach((key) => {
      const value = config[key];
      if (value !== undefined) {
        params.set(key, `${value}`);
      } else {
        params.delete(key);
      }
    });

    window.history.replaceState(
      {},
      '',
      `${window.location.pathname}?${params}`
    );
  }, [JSON.stringify(config)]);

  return Object.keys(config).reduce<Config>(
    (acc, key) => ({
      ...acc,
      [key]: config[key] === 0 ? 0 : config[key] || search.get(key),
    }),
    {} as Config
  );
};
