import { ModalProps } from 'components/Modal';
import { Description, Title, TitleWrapper } from 'components/Modal/styled';
import { Close } from 'assets/';
import { StyledModal, Content, CloseButton } from './styled';

export interface BannerModalProps extends ModalProps {
  banner: string;
}

export const BannerModal = ({
  banner,
  isVisible,
  onCancel,
  destroyOnClose,
  centered,
  title,
  footer,
  subTitle,
  children,
}: BannerModalProps) => {
  return (
    <StyledModal
      title={null}
      footer={null}
      isVisible={isVisible}
      onCancel={onCancel}
      showCancelButton={false}
      showOkButton={false}
      closable={false}
      destroyOnClose={destroyOnClose}
      centered={centered}
      banner={banner}
    >
      <Content>
        <TitleWrapper>
          <Title>{title}</Title>
          {subTitle && <Description>{subTitle}</Description>}
        </TitleWrapper>
        {children}
        {footer}
        <CloseButton
          icon={<Close />}
          type="ghost"
          shape="circle"
          onClick={onCancel}
        />
      </Content>
    </StyledModal>
  );
};
