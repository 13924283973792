import { APIRoutes } from 'constants/';
import {
  CurrentUser,
  Preferences,
  CurrentUserInfo,
  CountriesInfo,
  UserAddress,
  UserPhoneInfo,
} from 'types/';
import { baseApi, UserTags } from './baseApi';

export type PreferencesPayload = Partial<Preferences>;

export interface PasswordChangePayload {
  old_password: string;
  new_password: string;
}

export interface ContactUsPayload {
  message: string;
  subject: string;
}

export interface VerifyEmailPayload {
  email_token: string;
}

export const userApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    currentUser: build.query<CurrentUser, void>({
      query: () => ({
        url: APIRoutes.UserInfoBasic,
        method: 'GET',
      }),
      providesTags: [UserTags.UserBasic],
    }),
    currentUserFullInfo: build.query<CurrentUserInfo, void>({
      query: () => ({
        url: APIRoutes.UserInfoFull,
        method: 'GET',
      }),
      providesTags: [UserTags.UserFull],
    }),
    changePassword: build.mutation<void, PasswordChangePayload>({
      query: (body) => ({
        url: APIRoutes.ChangePassword,
        method: 'PATCH',
        body,
      }),
    }),
    initiateVerifyEmail: build.mutation<void, void>({
      query: () => ({
        url: APIRoutes.VerifyEmailInitiate,
        method: 'POST',
      }),
      invalidatesTags: [UserTags.UserFull],
    }),
    verifyEmail: build.mutation<void, VerifyEmailPayload>({
      query: (body) => ({
        url: APIRoutes.VerifyEmailSubmit,
        method: 'POST',
        body,
      }),
      invalidatesTags: [UserTags.UserBasic, UserTags.UserFull],
    }),
    updateAddress: build.mutation<void, UserAddress>({
      query: (body) => ({
        url: APIRoutes.UserAddress,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [UserTags.UserFull],
    }),
    updatePhone: build.mutation<void, UserPhoneInfo>({
      query: (body) => ({
        url: APIRoutes.UserPhone,
        method: 'PUT',
        body,
      }),
      invalidatesTags: [UserTags.UserFull],
    }),
    deleteUser: build.mutation<void, void>({
      query: (body) => ({
        url: APIRoutes.DeleteAccount,
        method: 'DELETE',
        body,
      }),
    }),
    contactUs: build.mutation<void, ContactUsPayload>({
      query: (body) => ({
        url: APIRoutes.ContactUs,
        method: 'POST',
        body,
      }),
    }),
    countries: build.query<CountriesInfo, void>({
      query: () => ({
        url: APIRoutes.Countries,
        method: 'GET',
      }),
    }),
    preferences: build.mutation<void, PreferencesPayload>({
      query: (body) => ({
        url: APIRoutes.Preferences,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: [UserTags.UserFull],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCurrentUserQuery,
  useCurrentUserFullInfoQuery,
  useLazyCurrentUserQuery,
  useLazyCurrentUserFullInfoQuery,
  useInitiateVerifyEmailMutation,
  useVerifyEmailMutation,
  useChangePasswordMutation,
  useUpdateAddressMutation,
  useUpdatePhoneMutation,
  useDeleteUserMutation,
  useContactUsMutation,
  useCountriesQuery,
  usePreferencesMutation,
} = userApi;
