import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export interface ContactUsModalContextState {
  isVisible: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

const defaultState: ContactUsModalContextState = {
  isVisible: false,
  open: () => undefined,
  close: () => undefined,
  toggle: () => undefined,
};

export const ContactUsModalContext = React.createContext(defaultState);

export const useContactUsModal = () => useContext(ContactUsModalContext);

export const ContactUsModalContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(defaultState.isVisible);
  const location = useLocation();

  const open = useCallback(() => {
    setIsVisible(true);
  }, []);
  const close = useCallback(() => setIsVisible(false), []);
  const toggle = useCallback(() => setIsVisible((prev) => !prev), []);

  useEffect(() => {
    close();
  }, [location.pathname]);

  const state = useMemo(
    () => ({
      isVisible,
      open,
      close,
      toggle,
    }),
    [isVisible]
  );

  return (
    <ContactUsModalContext.Provider value={state}>
      {children}
    </ContactUsModalContext.Provider>
  );
};

export const withContactUsModalContext = <P extends {}>(
  Component: React.ComponentType<P>
) => {
  return (props: P) => {
    return (
      <ContactUsModalContextProvider>
        <Component {...props} />
      </ContactUsModalContextProvider>
    );
  };
};
