import { SVGProps } from 'react';

export const Menu = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="1"
      y="3"
      width="14"
      height="2"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <rect
      x="1"
      y="11"
      width="14"
      height="2"
      fill="currentColor"
      fillOpacity="0.85"
    />
    <rect
      x="1"
      y="7"
      width="14"
      height="2"
      fill="currentColor"
      fillOpacity="0.85"
    />
  </svg>
);
