import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import type { RootState } from 'store/';
import { DEFAULT_DATE_FORMAT } from 'constants/';
import { TCountryCode } from 'countries-list';

/**
 * string is a date string in ISO 8601 format
 * */
export type SupplierTransactionsFilterDateRange = [
  /**
   * @format ISO 8601 date string in UTC timezone
   * @example 2021-09-01T00:00:00
   */
  string,
  /**
   * @format ISO 8601 date string in UTC timezone
   * @example 2021-09-30T23:59:59
   */
  string
];

export interface SupplierTransactionsFilterState {
  dateRange: SupplierTransactionsFilterDateRange;
  countryCodes: TCountryCode[];
  mid: string;
  pid: string;
}

const initialState: SupplierTransactionsFilterState = {
  dateRange: [
    dayjs().subtract(1, 'month').startOf('month').format(DEFAULT_DATE_FORMAT),
    dayjs().format(DEFAULT_DATE_FORMAT),
  ],
  countryCodes: [],
  mid: '',
  pid: '',
};

export const supplierTransactionsFilterSlice = createSlice({
  name: 'supplierTransactionsFilter',
  initialState,
  reducers: {
    setTransactionsDateRange: (
      state,
      { payload }: PayloadAction<SupplierTransactionsFilterState['dateRange']>
    ) => {
      state.dateRange = payload;
    },
    resetTransactionsDateRange: (state) => {
      state.dateRange = initialState.dateRange;
    },
    setTransactionsCountryCodes: (
      state,
      {
        payload,
      }: PayloadAction<SupplierTransactionsFilterState['countryCodes']>
    ) => {
      state.countryCodes = payload;
    },
    resetTransactionsCountryCodes: (state) => {
      state.countryCodes = initialState.countryCodes;
    },
    setTransactionsMid: (
      state,
      { payload }: PayloadAction<SupplierTransactionsFilterState['mid']>
    ) => {
      state.mid = payload;
    },
    resetTransactionsMid: (state) => {
      state.mid = initialState.mid;
    },
    setTransactionsPid: (
      state,
      { payload }: PayloadAction<SupplierTransactionsFilterState['pid']>
    ) => {
      state.pid = payload;
    },
    resetTransactionsPid: (state) => {
      state.pid = initialState.pid;
    },
  },
});

export const {
  setTransactionsDateRange,
  resetTransactionsDateRange,
  setTransactionsCountryCodes,
  resetTransactionsCountryCodes,
  setTransactionsMid,
  setTransactionsPid,
  resetTransactionsMid,
  resetTransactionsPid,
} = supplierTransactionsFilterSlice.actions;

export const getSupplierTransactionsFilterStore = (state: RootState) =>
  state.supplierTransactionsFilterStore;
