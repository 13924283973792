import { useEffect, useState } from 'react';
import { AlertProps } from 'antd';
import { ErrorIcon } from 'assets';
import { StyledAlert } from './styled';

interface CustomAlertProps extends AlertProps {
  timeout?: number;
  autoClose?: boolean;
  visible?: boolean;
}

export const Alert = ({
  timeout = 3000,
  autoClose = false,
  visible = true,
  ...props
}: CustomAlertProps) => {
  const isError = props.type === 'error';
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (visible) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [visible]);

  useEffect(() => {
    if (autoClose && isVisible) {
      const timer = setTimeout(() => {
        setIsVisible(false);
      }, timeout);
      return () => clearTimeout(timer);
    }
  }, [autoClose, timeout, isVisible]);

  if (!isVisible) return null;

  return (
    <StyledAlert
      showIcon
      icon={isError && <ErrorIcon />}
      $isError={isError}
      closable
      afterClose={() => setIsVisible(false)}
      {...props}
    />
  );
};
