import dayjs from 'dayjs';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import isoWeek from 'dayjs/plugin/isoWeek';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import { useAppSelector } from 'store';
import { getSupplierStatsFilterStore } from 'store/slices';
import { SupplierStatsDateGrouping } from 'store/api/types';

dayjs.extend(weekOfYear);
dayjs.extend(isoWeek);
dayjs.extend(advancedFormat);
dayjs.extend(quarterOfYear);

export const monthDays = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

export const getMonthSize = (month: number, year: number) => {
  const monthLength = monthDays.slice();

  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }

  return monthLength[month - 1];
};

export const getDateGroupingDateFormat = (dateString: string) => {
  const { dateGrouping } = useAppSelector(getSupplierStatsFilterStore);

  const date = dayjs(dateString);

  switch (dateGrouping) {
    // TODO: uncomment other values when BE will be ready
    // case SupplierStatsDateGrouping.Second:
    //   return date.format('ss:SSS, h:mm A, DD MMM, YYYY');

    // TODO: uncomment other values when BE will be ready
    // case SupplierStatsDateGrouping.Minute:
    //   return date.format('h:mm A, DD MMM, YYYY');

    case SupplierStatsDateGrouping.Hour:
      return date.format('h:mm A DD MMM, YYYY');

    case SupplierStatsDateGrouping.Day:
      return date.format('DD MMM, YYYY');

    case SupplierStatsDateGrouping.Week: {
      return `${date.startOf('isoWeek').format('MM/DD/YYYY')} - ${date
        .endOf('isoWeek')
        .format('MM/DD/YYYY')}`;
    }

    case SupplierStatsDateGrouping.Month:
      return date.format('MMM, YYYY');

    // TODO: uncomment other values when BE will be ready
    // case SupplierStatsDateGrouping.Quarter: {
    //   return `Q${date.quarter()} ${date.format('YYYY')}`;
    // }

    // TODO: uncomment other values when BE will be ready
    // case SupplierStatsDateGrouping.Year:
    //   return date.format('YYYY');

    default:
      return date.format('h:mm A, DD MMM, YYYY');
  }
};
