import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

export const researchDefenderApi = createApi({
  reducerPath: 'researchDefenderApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_RESEARCH_DEFENDER_API,
    credentials: 'include',
  }),
  endpoints: (build) => ({
    getResearchDefenderToken: build.query<{ results: any[] }, void>({
      query: () => ({
        url: `${process.env.REACT_APP_RESEARCH_DEFENDER_GET_TOKEN_URL}${
          process.env.REACT_APP_RESEARCH_DEFENDER_PUBLISHABLE_KEY
        }?tokens=${process.env.REACT_APP_RESEARCH_DEFENDER_NUMBER_OF_TOKENS}${
          process.env.REACT_APP_RESEARCH_DEFENDER_ADDITIONAL_PARAMS || ''
        }`,
        method: 'GET',
      }),
    }),
  }),
});

export const { useLazyGetResearchDefenderTokenQuery } = researchDefenderApi;
