import { SVGProps } from 'react';

export const OnboardingEmptyCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="0.5"
      y="0.5"
      width="15"
      height="15"
      rx="7.5"
      stroke="#F7B7BA"
      strokeDasharray="1 1"
    />
  </svg>
);
