import { Typography } from 'antd';
import styled from 'styled-components';
import { breakpoint, color } from 'helpers/';

const { Link, Text } = Typography;

export const StyledLink = styled(Link)`
  && {
    text-decoration: none;

    svg {
      font-size: 12px;
      vertical-align: middle;
      color: ${color('neutral.800')};
    }
  }
`;

export const LinkText = styled(Text)`
  text-decoration: underline;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;

export const Group = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupTitle = styled(Text)`
  && {
    font-weight: 700;
    font-size: 16px;
    line-height: 21px;
    color: ${color('neutral.800')};
    padding-bottom: 6px;
  }
`;

export const GroupItemHeader = styled.div`
  display: flex;
  flex-direction: column;
`;

export const GroupItemTitle = styled(Text)`
  && {
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: ${color('neutral.800')};
  }
`;

export const GroupItemDescription = styled(Text)`
  && {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${color('neutral.600')};
  }
`;

export const GroupItemKey = styled(Text)`
  && {
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: ${color('neutral.600')};
  }
`;

export const GroupItem = styled.div`
  display: grid;
  gap: 8px;
  grid-template-columns: 1fr max-content min-content;
  ${breakpoint('md')} {
    row-gap: 16px;
    grid-template-columns: 1fr min-content;
    ${GroupItemHeader} {
      grid-column: 1 / 3;
    }
    ${GroupItemKey} {
      text-align: right;
    }
  }
  align-items: center;
  padding: 16px;
  border-top: 1px solid ${color('neutral.200')};
  border-left: 1px solid ${color('neutral.200')};
  border-right: 1px solid ${color('neutral.200')};
  &:first-of-type {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }
  &:last-of-type {
    border-bottom: 1px solid ${color('neutral.200')};
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }
`;
