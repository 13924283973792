import React from 'react';
import { Button, Checkbox, Radio, Typography } from 'antd';
import styled, { css } from 'styled-components';
import { color, radius } from 'helpers/';

const { Title: AntdTitle, Text } = Typography;

export const FormLayout = styled.div<{ gap?: number }>`
  position: relative;
  display: grid;
  grid-template-columns: 100%;
  grid-auto-flow: row;
  gap: ${({ gap }) => (!gap && gap !== 0 ? 16 : gap)}px;
  margin-bottom: auto;
`;

export const ItemLayout = styled.div`
  display: grid;
  grid-auto-flow: row;
  gap: 0;
  margin-bottom: auto;
  width: 100%;
`;

export const Title = styled(AntdTitle)`
  && {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 40px;
  }
  &&.ant-typography {
    margin-top: 0;
    margin-bottom: 16px;
  }
`;
Title.defaultProps = {
  level: 3,
};

export const SubTitle = styled(Text)`
  && {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #8c8c8c;
  }
`;

export const Error = styled(Text)`
  && {
    font-size: 12px;
    line-height: 16px;
    margin-top: 8px;
    display: flex;
    align-items: center;
    gap: 2px;

    svg {
      margin-right: 4px;
    }
  }
`;
Error.defaultProps = {
  type: 'danger',
};

export const Label = styled.label`
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: ${color('neutral.600')};
  margin-bottom: 2px;
`;

export const baseInputCSS = css<{ isError?: boolean }>`
  box-sizing: border-box;
  margin: 0;
  padding: 11px 12px;
  color: ${color('neutral.1000')};
  list-style: none;
  font-family: 'DM Sans';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  position: relative;
  display: inline-block;
  width: 100%;
  min-width: 0;
  height: 40px;
  background-color: ${color('neutral.0')};
  background-image: none;
  border-width: 1px;
  border-style: solid;
  border-color: ${color('neutral.200')};
  border-radius: ${radius('base')};
  transition: all 0.2s;
  &:hover {
    border-color: ${color('neutral.1000')};
  }
  &:focus {
    border-color: ${color('neutral.1000')};
    outline: none;
  }
  ${({ isError }) =>
    isError &&
    css`
      background-color: ${color('error.100')};
      border-color: ${color('error')};
      &:hover {
        border-color: ${color('error')};
      }
    `}
  &:disabled {
    background: ${color('neutral.200')};
    border: 1px solid ${color('neutral.300')};
    color: ${color('neutral.400')};
    cursor: not-allowed;
    &:hover {
      border-color: ${color('neutral.400')};
    }
  }
  &::placeholder {
    color: ${color('neutral.300')};
  }
`;

export const FormInput: (
  props: React.HTMLProps<HTMLInputElement> & { isError?: boolean }
) => JSX.Element = styled.input<{ isError?: boolean }>`
  ${baseInputCSS}
`;

export const FormTextArea: (
  props: React.HTMLProps<HTMLTextAreaElement> & { isError?: boolean }
) => JSX.Element = styled.textarea<{ isError?: boolean }>`
  ${baseInputCSS}
  height: 70px;
`;

export const Submit = styled(Button)`
  && {
    margin: 16px 0;
    justify-content: center;
  }
`;
Submit.defaultProps = {
  size: 'middle',
  type: 'primary',
  htmlType: 'submit',
  block: true,
};

export const FormCheckbox = styled(Checkbox)``;
export const FormCheckboxGroup = styled(Checkbox.Group)``;
export const FormRadio = styled(Radio)``;
export const FormRadioGroup = styled(Radio.Group)``;
