import React from 'react';
import { Button as AntdButton } from 'antd';

export type AdditionalType = 'secondary' | 'secondary-black' | 'link-red';
const AdditionalTypes: AdditionalType[] = [
  'secondary',
  'secondary-black',
  'link-red',
];

const withAdditionalTypes = <P extends { className?: string; type?: string }>(
  Component: React.ComponentType<P>
): React.ComponentType<
  Omit<P, 'type'> & { type?: P['type'] | AdditionalType }
> => {
  return ((props: P) => {
    const { type, className } = props;

    const additionalClassnames = AdditionalTypes.includes(
      type as AdditionalType
    )
      ? `${type}-button`
      : '';

    const getTransformedType = () => {
      switch (type) {
        case 'secondary':
          return 'primary';
        case 'secondary-black':
          return 'primary';
        case 'link-red':
          return 'link';

        default:
          return type;
      }
    };

    return (
      <Component
        {...props}
        className={`${className} ${additionalClassnames}`}
        type={getTransformedType()}
      />
    );
  }) as React.ComponentType<
    Omit<P, 'type'> & { type?: P['type'] | AdditionalType }
  >;
};

export const Button = withAdditionalTypes(AntdButton);
