import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { OnboardingModalConfig } from './config';

export interface OnboardingModalContextState {
  isVisible: boolean;
  open: (config: OnboardingModalConfig) => void;
  close: () => void;
  config?: OnboardingModalConfig;
}

const defaultState: OnboardingModalContextState = {
  isVisible: false,
  open: () => undefined,
  close: () => undefined,
};

export const OnboardingModalContext = React.createContext(defaultState);

export const useOnboardingModal = () => useContext(OnboardingModalContext);

export const OnboardingModalContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(defaultState.isVisible);
  const [config, setConfig] = useState<OnboardingModalConfig>();

  const location = useLocation();

  const open = useCallback((config: OnboardingModalConfig) => {
    setConfig(config);
    setIsVisible(true);
  }, []);
  const close = useCallback(() => {
    setConfig(undefined);
    setIsVisible(false);
  }, []);

  useLayoutEffect(() => {
    close();
  }, [location.pathname]);

  const state: OnboardingModalContextState = useMemo(
    () => ({
      isVisible,
      open,
      close,
      config,
    }),
    [isVisible]
  );

  return (
    <OnboardingModalContext.Provider value={state}>
      {children}
    </OnboardingModalContext.Provider>
  );
};
