import { SVGProps } from 'react';

export const Performance = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect
      x="2.75"
      y="5.75"
      width="18.5"
      height="12.5"
      rx="2.25"
      fill="white"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M7 14L9.24439 11.8197C9.63244 11.4428 10.2499 11.4428 10.638 11.8197L12.1856 13.3231C12.5736 13.7001 13.1911 13.7001 13.5791 13.3231L17 10"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M12 5L12 4"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M6 22L8 19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
    <path
      d="M18 22L16 19"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);
