import { SVGProps } from 'react';

export const InfoIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM7.40289 5.89051H8.63672V4.65668H7.40289L7.40289 5.89051ZM7.40289 11.4229H8.63672V7.12434L7.40289 7.12434L7.40289 11.4229Z"
      fill="currentColor"
    />
  </svg>
);
