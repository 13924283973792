/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable react/prefer-stateless-function */
import React from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { log } from 'utils/logService';
import { EventIds } from 'constants/';
import { Error } from 'pages/Error';
import { RootState, store } from 'store/';
import { setIsErrorVisible } from 'store/slices';

const mapState = (state: RootState) => ({
  hasError: state.errorStore.isErrorVisible,
});
const mapDispatch = {
  setIsErrorVisible,
};
const connector = connect(mapState, mapDispatch);
type ErrorBoundaryProps = ConnectedProps<typeof connector>;

class ErrorBoundaryInner extends React.Component<
  React.PropsWithChildren<ErrorBoundaryProps>,
  {}
> {
  static getDerivedStateFromError() {
    store.dispatch(setIsErrorVisible(true));
    return {};
  }

  componentDidCatch(error: Error, errorInfo: any) {
    log.error(
      'Component error boundary has cought an error',
      EventIds.ComponentUnhandledError,
      error,
      errorInfo
    );
  }

  render() {
    const { children, hasError, setIsErrorVisible } = this.props;

    const handleClear = () => store.dispatch(setIsErrorVisible(false));

    if (hasError) {
      return <Error onClear={handleClear} />;
    }

    return children;
  }
}

export const ErrorBoundary = connector(ErrorBoundaryInner);
