import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import { ThemeProvider } from 'styled-components';
import { defaultTheme } from 'theme/';
import { store } from 'store/';
import { color, getThemeHelper, radius, stripUnits } from 'helpers/';
import { ErrorBoundary } from 'components/ErrorBoundary';
import { PayOutModalContextProvider } from 'components/PayOutModal';
import {
  CookieSettingsModal,
  CookieSettingsModalContextProvider,
} from 'components/CookieSettingsModal';
import { LocaleProvider } from 'components/LocaleProvider';
import {
  OnboardingModal,
  OnboardingModalContextProvider,
} from 'components/OnboardingModal';
import { BlockedUser } from 'components/BlockedUser';
import { AntdStylesOverride } from './AntdOverrides.styles';

export const Providers = ({ children }: React.PropsWithChildren<{}>) => {
  const getColor = getThemeHelper(color, defaultTheme);
  const getRadius = getThemeHelper(radius, defaultTheme);

  return (
    <BrowserRouter>
      <Provider store={store}>
        <LocaleProvider>
          <ThemeProvider theme={defaultTheme}>
            <ConfigProvider
              theme={{
                token: {
                  // Colors: General
                  colorText: getColor('neutral.800'),
                  colorTextBase: getColor('neutral.800'),
                  colorBgBase: getColor('neutral.100'),
                  colorBgContainer: getColor('neutral.0'),
                  colorBgContainerDisabled: getColor('neutral.300'),
                  colorBorder: getColor('neutral.200'),
                  // Colors: Error
                  colorError: getColor('error'),
                  colorErrorBorder: getColor('error.100'),
                  colorErrorBg: getColor('error.100'),
                  // Colors: Info
                  colorInfo: getColor('info'),
                  colorInfoBg: getColor('info.100'),
                  colorInfoBorder: getColor('info.100'),
                  // Colors: Primary
                  colorPrimary: getColor('primary'),
                  colorPrimaryHover: getColor('primary.500'),
                  colorPrimaryActive: getColor('primary.700'),
                  colorPrimaryBg: 'transparent',
                  colorPrimaryBorder: getColor('primary.100'),
                  // Colors: Success
                  colorSuccess: getColor('success'),
                  colorSuccessBg: getColor('success.200'),
                  colorSuccessBorder: getColor('success.200'),
                  // Colors: Warning
                  colorWarning: getColor('warning'),
                  colorWarningBg: getColor('warning.100'),
                  colorWarningBorder: getColor('warning.100'),
                  // Colors: Link
                  colorLink: getColor('primary'),
                  colorLinkHover: getColor('primary.500'),
                  colorLinkActive: getColor('primary.700'),
                  // Other tokens
                  borderRadius: stripUnits(getRadius('base')),
                  linkDecoration: 'underline',
                  controlHeight: 40,
                  fontFamily: `'DM Sans'`,
                  fontSize: 14,
                  lineType: 'solid',
                  lineWidth: 1,
                  motionUnit: 0.1,
                  sizeStep: 4,
                  sizeUnit: 4,
                  wireframe: false,
                  zIndexBase: 0,
                  zIndexPopupBase: 1000,
                },
                components: {
                  Button: {
                    fontSize: 16,
                    lineHeight: 1.5,
                  },
                  Select: {
                    colorPrimaryHover: getColor('neutral.1000'),
                    fontSize: 16,
                  },
                  Checkbox: {
                    controlInteractiveSize: 16,
                  },
                  DatePicker: {
                    colorBgBase: getColor('primary.300'),
                  },
                  Calendar: {
                    colorBgBase: getColor('primary.300'),
                    colorBgContainer: getColor('primary.300'),
                  },
                  Table: {
                    padding: 12,
                  },
                },
              }}
              getPopupContainer={(triggerNode) =>
                triggerNode?.parentNode as HTMLElement
              }
            >
              <ErrorBoundary>
                <AntdStylesOverride />
                <PayOutModalContextProvider>
                  <CookieSettingsModalContextProvider>
                    <OnboardingModalContextProvider>
                      <BlockedUser>{children}</BlockedUser>
                      <OnboardingModal />
                    </OnboardingModalContextProvider>
                    <CookieSettingsModal />
                  </CookieSettingsModalContextProvider>
                </PayOutModalContextProvider>
              </ErrorBoundary>
            </ConfigProvider>
          </ThemeProvider>
        </LocaleProvider>
      </Provider>
    </BrowserRouter>
  );
};
