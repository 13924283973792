export enum OnboardingState {
  ONBOARD_SERVICE_FLOW_01_STAGE_01 = 'ONBOARD_SERVICE_FLOW_01_STAGE_01',
  ONBOARD_SERVICE_FLOW_01_STAGE_02 = 'ONBOARD_SERVICE_FLOW_01_STAGE_02',
  ONBOARD_SERVICE_FLOW_01_STAGE_03 = 'ONBOARD_SERVICE_FLOW_01_STAGE_03',
  ONBOARD_SERVICE_FLOW_01_STAGE_04 = 'ONBOARD_SERVICE_FLOW_01_STAGE_04',
}

export enum SurveysState {
  ONBOARD_SURVEYS_FLOW_01_STAGE_01 = 'ONBOARD_SURVEYS_FLOW_01_STAGE_01',
  ONBOARD_SURVEYS_FLOW_01_STAGE_02 = 'ONBOARD_SURVEYS_FLOW_01_STAGE_02',
  ONBOARD_SURVEYS_FLOW_01_STAGE_03 = 'ONBOARD_SURVEYS_FLOW_01_STAGE_03',
  ONBOARD_SURVEYS_FLOW_01_STAGE_04 = 'ONBOARD_SURVEYS_FLOW_01_STAGE_04',
}

export interface UserOnboardingState {
  data: {
    points_last_survey?: number;
  };
  description: string;
  show_popup: boolean;
  state: OnboardingState;
}

export interface UserSurveysState {
  data: {
    points_all_surveys?: number;
    points_last_survey?: number;
    surveys_remaining_to_unlock?: number;
  };
  description: string;
  show_popup: boolean;
  state: SurveysState;
}

export interface UserState {
  is_admin?: string;
  onboard_service?: UserOnboardingState;
  onboard_surveys?: UserSurveysState;
}
