import { Select as AntdSelect, SelectProps } from 'antd';
import { color } from 'helpers';
import React from 'react';
import styled from 'styled-components';

export const Select = styled<React.ComponentType<SelectProps>>(AntdSelect)`
  width: 100%;
  max-width: inherit;
  .ant-select-selection-overflow-item {
    max-width: 50%;
    & > span {
      max-width: calc(100% - 2px);
    }
  }
`;

export const Container = styled.div`
  .ant-select-item-option-content.ant-select-item-option-content {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${color('neutral.800')};
  }

  .ant-select-item-option-selected.ant-select-item-option-selected,
  .ant-select-item-option-active.ant-select-item-option-active {
    background: ${color('neutral.100')};
    border-radius: 4px;

    .ant-select-item-option-state.ant-select-item-option-state {
      color: ${color('neutral.800')};
    }
  }

  .rc-virtual-list-holder-inner {
    gap: 2px;
  }
`;
