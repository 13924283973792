import { SVGProps } from 'react';

export const ContactUs = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.61309 17.7576L7.4503 17.258L6.92514 17.2404C5.71468 17.1998 4.75 16.2129 4.75 15V6C4.75 4.75721 5.75721 3.75 7 3.75H19C20.2428 3.75 21.25 4.75721 21.25 6V15C21.25 16.2428 20.2428 17.25 19 17.25H11.916H11.5966L11.3752 17.4803L8.78783 20.1727C8.78772 20.1728 8.7876 20.1729 8.78749 20.1731C8.655 20.3101 8.42722 20.256 8.36909 20.0776L7.61309 17.7576Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      d="M4.61309 20.7576L4.4503 20.258L3.92514 20.2404C2.71468 20.1998 1.75 19.2129 1.75 18V9C1.75 7.75721 2.75721 6.75 4 6.75H16C17.2428 6.75 18.25 7.75721 18.25 9V18C18.25 19.2428 17.2428 20.25 16 20.25H8.916H8.59657L8.37523 20.4803L5.78783 23.1727C5.78772 23.1728 5.7876 23.1729 5.78749 23.1731C5.655 23.3101 5.42722 23.256 5.36909 23.0776L4.61309 20.7576Z"
      fill="white"
      stroke="currentColor"
      strokeWidth="1.5"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.10305 15.3709C6.31249 15.0135 6.77197 14.8936 7.12934 15.1031C8.22383 15.7445 9.02937 16.0031 9.77269 16.0001C10.5163 15.9971 11.3058 15.7318 12.3688 15.1043C12.7255 14.8937 13.1854 15.0121 13.396 15.3688C13.6065 15.7255 13.4881 16.1854 13.1314 16.396C11.9617 17.0865 10.9018 17.4955 9.77881 17.5001C8.65557 17.5047 7.57746 17.1043 6.37091 16.3972C6.01354 16.1878 5.89362 15.7283 6.10305 15.3709Z"
      fill="currentColor"
    />
  </svg>
);
