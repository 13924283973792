import { SVGProps } from 'react';

export const SelectedCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 32 32"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="16" cy="16" r="14" fill="#EB4B53" />
    <path
      d="M14.0902 19.3437L23.403 10.0298L24.8366 11.4624L14.0902 22.2089L7.64258 15.7612L9.07516 14.3286L14.0902 19.3437Z"
      fill="white"
    />
  </svg>
);
