import { SVGProps } from 'react';

export const OnboardingCheckedCheckmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect width="16" height="16" rx="8" fill="#239E6A" />
    <path
      d="M7.15792 11.9838L7.14134 11.9999L3 7.95902L4.3485 6.64322L7.15798 9.38457L11.6515 5L13 6.3158L7.17456 12L7.15792 11.9838Z"
      fill="white"
    />
  </svg>
);
