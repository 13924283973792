import { SVGProps } from 'react';

export const VisibilityOff = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.21206 8.76801C2.06818 9.9269 4.22249 12.2637 7.675 12.2637C11.1275 12.2637 13.2818 9.9269 14.1379 8.76801C14.4207 8.38528 14.4207 7.87838 14.1379 7.49564C13.2818 6.33675 11.1275 4 7.675 4C4.22249 4 2.06818 6.33675 1.21206 7.49564C0.929313 7.87838 0.929314 8.38528 1.21206 8.76801ZM6.29772 8.13183C6.29772 7.37118 6.91435 6.75455 7.675 6.75455C8.43565 6.75455 9.05228 7.37118 9.05228 8.13183C9.05228 8.89248 8.43565 9.5091 7.675 9.5091C6.91435 9.5091 6.29772 8.89248 6.29772 8.13183ZM7.675 5.37728C6.1537 5.37728 4.92045 6.61053 4.92045 8.13183C4.92045 9.65312 6.1537 10.8864 7.675 10.8864C9.1963 10.8864 10.4296 9.65312 10.4296 8.13183C10.4296 6.61053 9.1963 5.37728 7.675 5.37728Z"
      fill="#383B48"
    />
  </svg>
);
