import { SerializedError } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { Route, RouteProps, useHistory } from 'react-router-dom';
import { Routes } from 'constants/';
import { getStringFromLocalStorage } from 'helpers/';
import { useAppSelector, useAppDispatch } from 'store/';
import { useRefreshMutation } from 'store/api';
import { getAuthStore, setIsLoggedIn } from 'store/slices';

let isRefreshing = false;

export const PublicRoute: React.FC<RouteProps> = ({
  component: Component,
  path,
  render,
  children,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();

  const wasEverSignedIn =
    getStringFromLocalStorage('wasEverSignedIn') === 'true';

  const [refresh, { isLoading }] = useRefreshMutation();
  const { isLoggedIn } = useAppSelector(getAuthStore);

  useEffect(() => {
    if (isLoggedIn || wasEverSignedIn) {
      return;
    }

    if (!isLoggedIn && !isRefreshing) {
      isRefreshing = true;
      refresh()
        .then((result) => {
          if ((result as { error: SerializedError }).error) {
            return;
          }

          dispatch(setIsLoggedIn(true));
        })
        .finally(() => {
          isRefreshing = false;
        });
    }
  }, [isLoggedIn, wasEverSignedIn, isRefreshing]);

  const renderComponent = (props: any) => {
    if (render) {
      return render(props);
    }

    if (Component) {
      return (<Component {...props} />) as any;
    }

    return null;
  };

  useEffect(() => {
    if (wasEverSignedIn || isLoggedIn) {
      const timeout = setTimeout(() => {
        history.push(Routes.Dashboard);
      }, 100);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [wasEverSignedIn, isLoggedIn]);

  if (isLoading || wasEverSignedIn || isLoggedIn) {
    return null;
  }

  if (children) {
    return (
      <Route path={path} {...rest}>
        {children}
      </Route>
    );
  }

  return <Route path={path} render={renderComponent} {...rest} />;
};
