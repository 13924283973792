import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { RootState } from 'store/';
import { EventIds } from 'constants/';

export interface ErrorState {
  isErrorVisible: boolean;
  endpointHandlers: Record<string, Partial<Record<EventIds, number>>>;
}

const initialState: ErrorState = {
  isErrorVisible: false,
  endpointHandlers: {},
};

export const errorSlice = createSlice({
  name: 'error',
  initialState,
  reducers: {
    setIsErrorVisible: (state, { payload }: PayloadAction<boolean>) => {
      state.isErrorVisible = payload;
    },
    clearError: (state) => {
      state.isErrorVisible = false;
    },
    addErrorHandler: (
      state,
      {
        payload: { endpointName, eventId },
      }: PayloadAction<{ endpointName: string; eventId: EventIds }>
    ) => {
      const handler = state.endpointHandlers[endpointName];

      if (handler) {
        if (handler[eventId]) {
          state.endpointHandlers[endpointName] = {
            ...handler,
            [eventId]: handler[eventId]! + 1,
          };
        } else {
          state.endpointHandlers[endpointName] = {
            ...handler,
            [eventId]: 1,
          };
        }

        return;
      }

      state.endpointHandlers[endpointName] = {
        [eventId]: 1,
      };
    },
    removeErrorHandler: (
      state,
      {
        payload: { endpointName, eventId },
      }: PayloadAction<{ endpointName: string; eventId: EventIds }>
    ) => {
      const handler = state.endpointHandlers[endpointName];

      if (!handler || !handler[eventId]) {
        return;
      }

      state.endpointHandlers[endpointName][eventId]! -= 1;
    },
  },
});

export const {
  setIsErrorVisible,
  clearError,
  addErrorHandler,
  removeErrorHandler,
} = errorSlice.actions;

export const getErrorStore = (state: RootState) => state.errorStore;
