import { LoadingOutlined } from '@ant-design/icons';
import React, { Suspense } from 'react';
import { Layout } from 'components/Layout';
import { Container } from './styled';

export const Fallback = () => {
  return (
    <Container>
      <LoadingOutlined />
    </Container>
  );
};

export const FallbackWithLayout = () => {
  return (
    <Layout>
      <Container>
        <LoadingOutlined />
      </Container>
    </Layout>
  );
};

export const withSuspense = <P extends {}>(
  Component: React.ComponentType<P>,
  fallback: React.ReactNode = <FallbackWithLayout />
) => {
  return (props: P) => {
    return (
      <Suspense fallback={fallback}>
        <Component {...props} />
      </Suspense>
    );
  };
};
