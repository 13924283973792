import React from 'react';
import { t } from '@lingui/macro';
import { useLocation } from 'react-router';
import { ButtonProps, Space } from 'antd';
import { useHistory } from 'react-router-dom';
import ErrorImage from 'assets/Error.png';
import { Routes } from 'constants/';
import { AdditionalType, Button } from 'components/Button';
import { useBreakpoint } from 'hooks/';
import { Container, Content, Description, Title } from './styled';

const InnerError = ({
  title = t`Uh oh! Something went wrong`,
  description = t`We're working hard to fix the issue as soon as possible. In the meantime, please try again later or contact our support team for assistance. We apologize for the inconvenience.`,
  image = ErrorImage,
  onClear = () => undefined,
  pathOnCTA = Routes.Dashboard,
  backButtonLabel = t`To Dashboard`,
  backButtonType = 'primary',
  actions,
}: {
  title?: React.ReactNode;
  description?: React.ReactNode;
  image?: string;
  onClear?: () => void;
  backButtonLabel?: React.ReactNode;
  backButtonType?: ButtonProps['type'] | AdditionalType;
  actions?: React.ReactNode[];
  pathOnCTA?: Routes | string;
}) => {
  const history = useHistory();
  const isMobile = useBreakpoint('md');

  // Hack. Allow overriding certain error page attributes. Pull this info
  // from `location.state`. If nothing there, business as usual.
  const location = useLocation();
  const { title_alt, description_alt, hide_button } = (location.state ||
    {}) as {
    title_alt?: string;
    description_alt?: string;
    hide_button?: boolean;
  };

  const handleGoToDashboardClick = () => {
    onClear();
    history.push(pathOnCTA);
  };

  return (
    <Container>
      <Content>
        <img src={image} alt={t`Error`} />
        <Title>{title_alt || title}</Title>
        <Description>{description_alt || description}</Description>
        <Space direction={isMobile ? 'vertical' : 'horizontal'}>
          {!hide_button ? (
            <Button
              type={backButtonType}
              onClick={handleGoToDashboardClick}
              block={isMobile}
            >
              {backButtonLabel}
            </Button>
          ) : null}
          {actions?.map((action, index) => (
            <React.Fragment key={index}>{action}</React.Fragment>
          ))}
        </Space>
      </Content>
    </Container>
  );
};

export const Error = Object.assign(InnerError, {
  Title,
  Description,
});
