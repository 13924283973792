import { SVGProps } from 'react';

export const Info = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx="8" cy="8" r="7.25" stroke="currentColor" strokeWidth="1.5" />
    <ellipse
      cx="8"
      cy="4"
      rx="1"
      ry="1"
      transform="rotate(-180 8 4)"
      fill="currentColor"
    />
    <path
      d="M8 12L8 7.2"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
    />
  </svg>
);
