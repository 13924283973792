import { SVGProps } from 'react';

export const WarningIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    fill="currentColor"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3 1C1.89543 1 1 1.89543 1 3V13C1 14.1046 1.89543 15 3 15H13C14.1046 15 15 14.1046 15 13V3C15 1.89543 14.1046 1 13 1H3ZM8.63715 10.1891H7.40332V11.4229H8.63715V10.1891ZM8.63715 4.65674H7.40332V8.95525H8.63715V4.65674Z"
      fill="currentColor"
    />
  </svg>
);
