import { APIRoutes } from 'constants/';
import { baseApi } from './baseApi';

export interface AffiliateTrackEventPayload {
  url: string;
  event_name?: string;
  context?: Record<string, string | number>;
}

export interface ResearchDefenderTokenPayload {
  researchdefender_tokens: any[];
}

export const analyticsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    affiliateTrackEvent: build.query<void, AffiliateTrackEventPayload>({
      query: (body) => ({
        url: APIRoutes.AffiliateTrackEvent,
        method: 'POST',
        body,
      }),
    }),
    researchDefenderToken: build.mutation<void, ResearchDefenderTokenPayload>({
      query: (body) => ({
        url: APIRoutes.ResearchDefenderToken,
        method: 'POST',
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useLazyAffiliateTrackEventQuery,
  useResearchDefenderTokenMutation,
} = analyticsApi;
