import ReactDOM from 'react-dom/client';
import { Providers } from 'components/Providers';
import { App } from './App';

import 'antd/dist/reset.css';

const root = ReactDOM.createRoot(document.getElementById('root')!);

root.render(
  <Providers>
    <App />
  </Providers>
);
