import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQueryWithReauth } from './baseQueryWithReauth';

export enum SurveyTags {
  Options = 'Options',
}

export enum UserTags {
  UserBasic = 'UserBasic',
  UserFull = 'UserFull',
}

export enum PayoutsTags {
  UserPaymentMethods = 'UserPaymentMethods',
  PayoutsHistory = 'PayoutsHistory',
}

export enum OnboardingTags {
  UserState = 'UserState',
}

export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithReauth,
  endpoints: () => ({}),
  tagTypes: [
    UserTags.UserBasic,
    UserTags.UserFull,
    PayoutsTags.UserPaymentMethods,
    PayoutsTags.PayoutsHistory,
    OnboardingTags.UserState,
    SurveyTags.Options,
  ],
});
