import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';
import type { TCountryCode } from 'countries-list';
import type { RootState } from 'store/';
import { SupplierStatsDateGrouping } from 'store/api/types';
import { DEFAULT_DATE_FORMAT } from 'constants/';

/**
 * string is a date string in ISO 8601 format
 * */
export type SupplierStatsFilterDateRange = [
  /**
   * @format ISO 8601 date string in UTC timezone
   * @example 2021-09-01T00:00:00
   */
  string,
  /**
   * @format ISO 8601 date string in UTC timezone
   * @example 2021-09-30T23:59:59
   */
  string
];

export interface SupplierStatsFilterState {
  dateRange: SupplierStatsFilterDateRange;
  dateGrouping: SupplierStatsDateGrouping;
  countryCodes: TCountryCode[];
  countryGrouping: boolean;
}

const initialState: SupplierStatsFilterState = {
  dateRange: [
    dayjs().subtract(1, 'month').startOf('month').format(DEFAULT_DATE_FORMAT),
    dayjs().format(DEFAULT_DATE_FORMAT),
  ],
  dateGrouping: SupplierStatsDateGrouping.Day,
  countryCodes: [],
  countryGrouping: false,
};

export const supplierStatsFilterSlice = createSlice({
  name: 'supplierStatsFilter',
  initialState,
  reducers: {
    setDateRange: (
      state,
      { payload }: PayloadAction<SupplierStatsFilterState['dateRange']>
    ) => {
      state.dateRange = payload;
    },
    setDateGrouping: (
      state,
      { payload }: PayloadAction<SupplierStatsDateGrouping>
    ) => {
      state.dateGrouping = payload;
    },
    setCountryCodes: (
      state,
      { payload }: PayloadAction<SupplierStatsFilterState['countryCodes']>
    ) => {
      state.countryCodes = payload;
    },
    setCountryGrouping: (state, { payload }: PayloadAction<boolean>) => {
      state.countryGrouping = payload;
    },
    resetDateRange: (state) => {
      state.dateRange = initialState.dateRange;
    },
    resetDateGrouping: (state) => {
      state.dateGrouping = initialState.dateGrouping;
    },
    resetCountryCodes: (state) => {
      state.countryCodes = initialState.countryCodes;
    },
    resetCountryGrouping: (state) => {
      state.countryGrouping = initialState.countryGrouping;
    },
  },
});

export const {
  setDateRange,
  setDateGrouping,
  setCountryCodes,
  setCountryGrouping,
  resetDateRange,
  resetDateGrouping,
  resetCountryCodes,
  resetCountryGrouping,
} = supplierStatsFilterSlice.actions;

export const getSupplierStatsFilterStore = (state: RootState) =>
  state.supplierStatsFilterStore;
