import { SVGProps } from 'react';

export const CloseGrey = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7 6.02793L10.4029 2.625L11.375 3.59707L7.97207 7L11.375 10.4029L10.4029 11.375L7 7.97207L3.59707 11.375L2.625 10.4029L6.02793 7L2.625 3.59707L3.59707 2.625L7 6.02793Z"
      fill="#A9ACBC"
    />
  </svg>
);
