import { SVGProps } from 'react';

export const BarsFull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect x="1" y="11" width="3" height="4" rx="1" fill="#70758F" />
    <rect x="6" y="7" width="3" height="8" rx="1" fill="#70758F" />
    <rect x="11" y="3" width="3" height="12" rx="1" fill="#70758F" />
  </svg>
);
