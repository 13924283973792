import React from 'react';
import { Navbar } from 'components/Navbar';
import { Footer } from 'components/Footer';
import { StyledLayout, StyledContent, ScrollContainer } from './styled';

export const Layout = ({
  children,
  noPadding,
}: {
  children: React.ReactNode;
  noPadding?: boolean;
}) => {
  return (
    <StyledLayout>
      <Navbar />
      <StyledContent>
        <ScrollContainer $noPadding={noPadding}>
          {children}
          <Footer />
        </ScrollContainer>
      </StyledContent>
    </StyledLayout>
  );
};
