import { SVGProps } from 'react';

export const Dashboard = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <mask id="path-1-inside-1_3072_262776" fill="white">
      <rect x="3" y="4" width="8" height="9" rx="1" />
    </mask>
    <rect
      x="3"
      y="4"
      width="8"
      height="9"
      rx="1"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-1-inside-1_3072_262776)"
    />
    <mask id="path-2-inside-2_3072_262776" fill="white">
      <rect x="12" y="11" width="8" height="9" rx="1" />
    </mask>
    <rect
      x="12"
      y="11"
      width="8"
      height="9"
      rx="1"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-2-inside-2_3072_262776)"
    />
    <mask id="path-3-inside-3_3072_262776" fill="white">
      <rect x="12" y="4" width="8" height="6" rx="1" />
    </mask>
    <rect
      x="12"
      y="4"
      width="8"
      height="6"
      rx="1"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-3-inside-3_3072_262776)"
    />
    <mask id="path-4-inside-4_3072_262776" fill="white">
      <rect x="3" y="14" width="8" height="6" rx="1" />
    </mask>
    <rect
      x="3"
      y="14"
      width="8"
      height="6"
      rx="1"
      stroke="currentColor"
      strokeWidth="3"
      mask="url(#path-4-inside-4_3072_262776)"
    />
  </svg>
);
