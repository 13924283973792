import { CheckboxProps, RadioGroupProps, RadioProps } from 'antd';
import { CheckboxGroupProps } from 'antd/lib/checkbox';
import React, { forwardRef } from 'react';
import {
  FieldValues,
  FormProvider,
  useFormContext,
  UseFormReturn,
} from 'react-hook-form';
import { ErrorIcon } from 'assets/';
import {
  FormLayout,
  ItemLayout,
  Title,
  Submit,
  Error,
  Label,
  FormInput,
  SubTitle,
  FormTextArea,
  FormCheckbox,
  FormCheckboxGroup,
  FormRadio,
  FormRadioGroup,
} from './styled';

export const Input = forwardRef<
  HTMLInputElement,
  React.HTMLProps<HTMLInputElement>
>((props, ref) => {
  const {
    formState: { errors },
  } = useFormContext();

  const isError = Boolean(errors[props.name ?? '']);

  return <FormInput ref={ref} isError={isError} {...props} />;
});

export const TextArea = forwardRef<
  HTMLTextAreaElement,
  React.HTMLProps<HTMLTextAreaElement> & { isAutoResizable?: boolean }
>((props, ref) => {
  const {
    formState: { errors },
  } = useFormContext();

  const handleInput = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    if (props.isAutoResizable) {
      const textarea = e.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }

    if (props.onInput) {
      props.onInput(e);
    }
  };

  const isError = Boolean(errors[props.name ?? '']);

  return (
    <FormTextArea
      ref={ref}
      isError={isError}
      onInput={handleInput}
      {...props}
    />
  );
});

export const Checkbox = (props: CheckboxProps) => {
  return <FormCheckbox {...props} />;
};

export const Radio = (props: RadioProps) => {
  return <FormRadio {...props} />;
};

export const RadioGroup = (props: RadioGroupProps) => {
  return <FormRadioGroup {...props} />;
};

export const CheckboxGroup = (props: CheckboxGroupProps) => {
  return <FormCheckboxGroup {...props} />;
};

export interface FormProps<T extends FieldValues>
  extends Omit<React.HTMLProps<HTMLFormElement>, 'form'> {
  form: UseFormReturn<T>;
  gap?: number;
}

export const FormComponent = <T extends FieldValues>({
  form,
  children,
  gap,
  ...props
}: FormProps<T>) => {
  return (
    <FormProvider {...form}>
      <form {...props}>
        <FormLayout gap={gap}>{children}</FormLayout>
      </form>
    </FormProvider>
  );
};

export interface ItemProps {
  className?: string;
  name: string;
  label?: string;
  style?: object;
  children: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
}

export const ErrorMessage = ({ name }: Pick<ItemProps, 'name'>) => {
  const {
    formState: { errors },
  } = useFormContext();

  if (!errors[name]?.message) {
    return null;
  }

  return (
    <Error>
      <ErrorIcon /> {errors[name]?.message?.toString()}
    </Error>
  );
};

export const Item = ({
  children,
  className,
  name,
  label,
  style,
}: ItemProps) => {
  return (
    <ItemLayout className={className} style={style}>
      {label && <Label htmlFor={name}>{label}</Label>}
      {children}
      <ErrorMessage name={name} />
    </ItemLayout>
  );
};

export const Form = Object.assign(FormComponent, {
  FormLayout,
  Item,
  Title,
  SubTitle,
  Input,
  TextArea,
  Submit,
  Checkbox,
  CheckboxGroup,
  Radio,
  RadioGroup,
  Label,
  ErrorMessage,
});
