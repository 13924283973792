import { SerializedError } from '@reduxjs/toolkit';
import React, { useEffect } from 'react';
import { Route, useLocation, RouteProps, useHistory } from 'react-router-dom';
import { Routes } from 'constants/';
import { useAppDispatch, useAppSelector } from 'store/';
import { useRefreshMutation } from 'store/api';
import { getAuthStore, setIsLoggedIn } from 'store/slices';

export type ProtectedRouteProps = Omit<RouteProps, 'location'>;

let isRefreshing = false;

export const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  component: Component,
  path,
  render,
  children,
  ...rest
}) => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const location = useLocation();
  const { isLoggedIn } = useAppSelector(getAuthStore);

  const [refresh] = useRefreshMutation();

  useEffect(() => {
    if (isLoggedIn) {
      return;
    }

    if (!isLoggedIn && !isRefreshing) {
      isRefreshing = true;

      refresh()
        .then((result) => {
          if ((result as { error: SerializedError }).error) {
            history.push({
              pathname: Routes.SignIn,
              state: {
                returnUrl: `${location.pathname}${location.search}`,
              },
            });
            return;
          }

          dispatch(setIsLoggedIn(true));
        })
        .finally(() => {
          isRefreshing = false;
        });
    }
  }, [isLoggedIn, location.pathname, location.search, isRefreshing]);

  const renderComponent = (props: any) => {
    if (isLoggedIn) {
      if (render) {
        return render(props);
      }

      if (Component) {
        return (<Component {...props} />) as any;
      }
    }

    return null;
  };

  if (children) {
    return (
      <Route path={path} {...rest}>
        {isLoggedIn && children}
      </Route>
    );
  }

  return <Route path={path} render={renderComponent} {...rest} />;
};
