import { SVGProps } from 'react';

export const Close = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8 6.88906L11.8891 3L13 4.11094L9.11094 8L13 11.8891L11.8891 13L8 9.11094L4.11094 13L3 11.8891L6.88906 8L3 4.11094L4.11094 3L8 6.88906Z"
      fill="black"
      fillOpacity="0.45"
    />
  </svg>
);
