import styled from 'styled-components';
import { breakpoint, color } from 'helpers/';
import { Button } from 'components/Button';

export const Container = styled.div`
  padding: 12px 20px;
  display: flex;
  gap: 18px;
  align-items: center;
  justify-content: center;

  ${breakpoint('md')} {
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    padding: 32px 20px 32px;
  }
`;

export const Logo = styled.img`
  height: 21px;
`;

export const StyledLink = styled.a`
  text-decoration: none;
`;

export const StyledButton = styled(Button)`
  &.ant-btn-link {
    span {
      text-decoration: none;
      transition: color 0.3s ease-out;
      color: ${color('neutral.500')};
      &:hover {
        color: ${color('primary.500')};
      }
    }
  }
`;
