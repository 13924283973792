import { t } from '@lingui/macro';
import { useCurrentUserFullInfoQuery } from 'store/api';
import { useHandleQueryError } from 'hooks/';
import { EventIds } from 'constants/';
import { Form } from 'components/Form';
import { Modal } from 'components/Modal';
import { Alert } from 'components/Alert';
import { ContactUsFormItems } from '../ContactUsFormItems';
import { useContactUsForm } from '../hooks';
import { useContactUsModal } from './ContactUsModalContext';

export const ContactUsModal = () => {
  const { isVisible, close } = useContactUsModal();

  const { data, endpointName } = useCurrentUserFullInfoQuery();
  useHandleQueryError(EventIds.UserFullInfoQueryError, endpointName);

  const { form, onSubmit, error } = useContactUsForm();

  const handleCancel = () => {
    close();
  };

  return (
    <Modal
      title={t`Contact Us`}
      subTitle={t`One of our Community Support team members will reach out as soon as possible.`}
      isVisible={isVisible}
      onOk={onSubmit}
      onCancel={handleCancel}
      okText={t`Send`}
      showCancelButton={false}
      centered
    >
      <Form form={form} style={{ marginBottom: '24px' }}>
        {error && (
          <Alert
            message={t`Uh oh! Something went wrong. Check your internet and try again.`}
            type="error"
          />
        )}
        <ContactUsFormItems email={data?.email} form={form} />
      </Form>
    </Modal>
  );
};
