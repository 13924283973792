import { APIRoutes } from 'constants/';
import {
  PayoutMethod,
  PayoutUnit,
  PayoutMethods,
  PayoutsHistory,
  PayoutHandlerInfo,
} from 'types/';
import { baseApi, UserTags, PayoutsTags, OnboardingTags } from './baseApi';

export interface ConnectPaypalPayload {
  code: string;
}

export interface CreatePayoutPayload {
  payout_method: PayoutMethod;
  unit_name: PayoutUnit;
  n_units: number;
  n_points: number;
}

export const payoutsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    payoutsHistory: build.query<PayoutsHistory, void>({
      query: () => ({
        url: APIRoutes.PayoutsHistory,
        method: 'GET',
      }),
      providesTags: [PayoutsTags.PayoutsHistory],
    }),
    payoutMethods: build.query<PayoutMethod[], void>({
      query: () => ({
        url: APIRoutes.PayoutMethods,
        method: 'GET',
      }),
      providesTags: [PayoutsTags.UserPaymentMethods],
    }),
    payoutHandlers: build.query<PayoutHandlerInfo[], void>({
      query: () => ({
        url: APIRoutes.PayoutHandlers,
        method: 'GET',
      }),
      providesTags: [PayoutsTags.UserPaymentMethods],
    }),
    supportedPayoutMethods: build.query<PayoutMethods, void>({
      query: () => ({
        url: APIRoutes.SupportedPayoutMethods,
        method: 'GET',
      }),
    }),
    createPayout: build.mutation<void, CreatePayoutPayload>({
      query: (body) => ({
        url: APIRoutes.CreatePayout,
        method: 'POST',
        body,
      }),
      invalidatesTags: [
        PayoutsTags.PayoutsHistory,
        UserTags.UserFull,
        UserTags.UserBasic,
        OnboardingTags.UserState,
      ],
    }),
    connectPaypal: build.mutation<void, ConnectPaypalPayload>({
      query: ({ code }) => ({
        url: `${APIRoutes.ConnectPaypal}?code=${code}`,
        method: 'GET',
      }),
      invalidatesTags: [PayoutsTags.UserPaymentMethods],
    }),
    disconnectPaypal: build.mutation<void, void>({
      query: () => ({
        url: APIRoutes.DisconnectPaypal,
        method: 'DELETE',
      }),
      invalidatesTags: [PayoutsTags.UserPaymentMethods],
    }),
  }),
  overrideExisting: false,
});

export const {
  usePayoutsHistoryQuery,
  useCreatePayoutMutation,
  usePayoutMethodsQuery,
  usePayoutHandlersQuery,
  useSupportedPayoutMethodsQuery,
  useConnectPaypalMutation,
  useDisconnectPaypalMutation,
} = payoutsApi;
