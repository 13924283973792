import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';

export interface ContactUsSuccessModalContextState {
  isVisible: boolean;
  open: () => void;
  close: () => void;
  toggle: () => void;
}

const defaultState: ContactUsSuccessModalContextState = {
  isVisible: false,
  open: () => undefined,
  close: () => undefined,
  toggle: () => undefined,
};

export const ContactUsSuccessModalContext = React.createContext(defaultState);

export const useContactUsSuccessModal = () =>
  useContext(ContactUsSuccessModalContext);

export const ContactUsSuccessModalContextProvider = ({
  children,
}: React.PropsWithChildren) => {
  const [isVisible, setIsVisible] = useState(defaultState.isVisible);
  const location = useLocation();

  const open = useCallback(() => {
    setIsVisible(true);
  }, []);
  const close = useCallback(() => setIsVisible(false), []);
  const toggle = useCallback(() => setIsVisible((prev) => !prev), []);

  useEffect(() => {
    close();
  }, [location.pathname]);

  const state = useMemo(
    () => ({
      isVisible,
      open,
      close,
      toggle,
    }),
    [isVisible]
  );

  return (
    <ContactUsSuccessModalContext.Provider value={state}>
      {children}
    </ContactUsSuccessModalContext.Provider>
  );
};

export const withContactUsSuccessModalContext = <P extends {}>(
  Component: React.ComponentType<P>
) => {
  return (props: P) => {
    return (
      <ContactUsSuccessModalContextProvider>
        <Component {...props} />
      </ContactUsSuccessModalContextProvider>
    );
  };
};
