import { SVGProps } from 'react';

export const CashIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g filter="url(#filter0_d_5501_291691)">
      <path
        d="M19 8C19 12.4176 15.4176 16 11 16C6.5824 16 3 12.4176 3 8C3 3.5824 6.5824 0 11 0C15.4176 0 19 3.5824 19 8Z"
        fill="#F6BD42"
      />
    </g>
    <path
      d="M11 1C7.12812 1 4 4.12812 4 8C4 11.8719 7.12812 15 11 15C14.8719 15 18 11.8719 18 8C18 4.12812 14.8719 1 11 1Z"
      fill="#FBDC9B"
    />
    <path
      d="M14 10.2105C14 12.4211 11.975 12.7368 11.6 12.7368V14H10.625V12.7368C10.325 12.7368 8 12.4211 8 9.73684H9.8C9.8 10.0526 9.8 11.3158 11.15 11.3158C12.275 11.3158 12.275 10.3684 12.275 10.2105C12.275 8.15789 8.375 9.02631 8.375 5.86842C8.375 3.89474 10.025 3.34211 10.775 3.34211V2H11.75V3.34211C12.35 3.42105 14 3.89474 14 6.26316H12.275C12.275 5.78947 12.125 4.76316 11.15 4.76316C10.1 4.76316 10.1 5.71053 10.1 5.86842C10.175 7.76316 14 6.97368 14 10.2105Z"
      fill="#F6BD42"
    />
    <defs>
      <filter
        id="filter0_d_5501_291691"
        x="0"
        y="0"
        width="22"
        height="22"
        filterUnits="userSpaceOnUse"
        colorinterpolation-filters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="3" />
        <feGaussianBlur stdDeviation="1.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.754167 0 0 0 0 0.54719 0 0 0 0 0.100556 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_5501_291691"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_5501_291691"
          result="shape"
        />
      </filter>
    </defs>
  </svg>
);
