import { t } from '@lingui/macro';
import { Routes } from 'constants/';
import { UserPoints } from '../UserPoints';
import { useMobileNavbar } from './MobileNavbarContext';
import { StyledDrawer, Menu, MenuItem, MenuLink } from './styled';

export const MobileNavbar = ({
  menuConfig,
}: {
  menuConfig: {
    icon: JSX.Element;
    title: string;
    condition: boolean;
    path?: Routes;
    onClick?: () => void;
  }[];
}) => {
  const { isVisible, close } = useMobileNavbar();

  const handleClick = (onClick?: () => void) => {
    if (onClick) {
      onClick();
    }
    close();
  };

  return (
    <StyledDrawer
      title={t`Menu`}
      placement="bottom"
      width="100%"
      onClose={close}
      open={isVisible}
      contentWrapperStyle={{ height: 520 }}
      getContainer="body"
    >
      <UserPoints />
      <Menu>
        {menuConfig
          .filter(({ condition }) => !!condition)
          .map((item) =>
            item.path ? (
              <MenuLink
                key={item.title}
                to={item.path}
                onClick={() => handleClick()}
              >
                {item.icon} {item.title}
              </MenuLink>
            ) : (
              <MenuItem
                key={item.title}
                onClick={() => handleClick(item.onClick)}
              >
                {item.icon} {item.title}
              </MenuItem>
            )
          )}
      </Menu>
    </StyledDrawer>
  );
};
