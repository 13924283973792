export enum EventIds {
  // Misc
  ComponentUnhandledError = 1001,
  ThemeHelperError = 1002,

  // Mutations
  ContactUsMutationError = 2001,

  AuthLogInMutationError = 2011,
  AuthSignUpMutationError = 2012,
  AuthLogOutMutationError = 2013,
  AuthResetPasswordInitiateMutationError = 2014,
  AuthPasswordResetSubmitMutationError = 2015,

  PayoutsCreateMutationError = 2021,
  PayoutsConnectPaypalMutationError = 2022,
  PayoutsDisconnectPayPalMutationError = 2023,

  CommitmentInitiateMutationError = 2031,
  CommitmentNextActionMutationError = 2032,
  CommitmentOutcomeMutationError = 2033,
  CommitmentSpecialInitiateMutationError = 2034,

  UserVerifyEmailInitiateMutationError = 2041,
  UserVerifyEmailSubmitMutationError = 2042,
  UserPreferencesMutationError = 2043,
  UserAddressMutationError = 2044,
  UserPhoneMutationError = 2045,
  UserChangePasswordMutationError = 2046,
  UserDeleteMutationError = 2047,

  SupplierLogInMutationError = 2061,
  SupplierSignUpMutationError = 2062,

  ResearchDefenderTokenMutationError = 2071,

  // Queries
  UserFullInfoQueryError = 3001,
  UserBasicInfoQueryError = 3002,

  PayoutsHistoryQueryError = 3011,
  PayoutsMethodsQueryError = 3012,
  PayoutsSupportedMethodsQueryError = 3013,
  PayoutsHandlerssQueryError = 3014,

  CommitmentOptionsQueryError = 3021,
  CommitmentHistoryQueryError = 3022,

  CoutriesQueryError = 3031,

  OnboardingStateQueryError = 3041,

  ResearchDefenderTokenQueryError = 3051,

  SupplierStatsQueryError = 3061,
  SupplierTransactionsQueryError = 3062,
}
