import styled, { css } from 'styled-components';
import { Alert } from 'antd';
import { color } from 'helpers/';

export const StyledAlert = styled(Alert)<{ $isError?: boolean }>`
  && {
    ${({ $isError }) =>
      $isError &&
      css`
        background-color: ${color('error.200')};
        border-color: ${color('error.200')};
        color: ${color('error.800')};
      `}
  }
`;
