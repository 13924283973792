import styled from 'styled-components';
import { Modal } from 'components/Modal';
import { color } from 'helpers';
import { Button } from 'components/Button';

export const StyledModal = styled(Modal)<{ banner: string }>`
  && {
    max-width: 442px;
    width: 100% !important;
    padding: 16px;
  }

  .ant-modal-content.ant-modal-content {
    background-image: url(${({ banner }) => banner});
    border-radius: 10px;
    width: 100%;
    height: 443px;
    position: relative;
    display: flex;
    .ant-modal-body.ant-modal-body {
      width: 100%;
    }
  }
`;

export const Content = styled.div`
  background-color: ${color('neutral.0')};
  padding: 24px 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin: 60px auto;
  width: fit-content;
  border-radius: 10px;
`;

export const CloseButton = styled(Button)`
  position: absolute;
  top: 16px;
  right: 0;
`;
