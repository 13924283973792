import { SVGProps } from 'react';

export const ArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.6484 4.64819L14.0001 8.9999L9.6484 13.3516L8.51172 12.2149L11.7267 8.9999L8.51172 5.78488L9.6484 4.64819Z"
      fill="currentColor"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12.8634 9.8038L2.41455 9.8038L2.41455 8.19629L12.8634 8.19629V9.8038Z"
      fill="currentColor"
    />
  </svg>
);
