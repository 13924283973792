import { t } from '@lingui/macro';

export const contactUsSubjects = [
  t`Feature Suggestion`,
  t`App or Website Error`,
  t`Account Frozen`,
  t`Broken Survey`,
  t`Payment Error`,
  t`My Complete was Rejected`,
  t`Missing Points`,
];
