import styled, { css } from 'styled-components';
import { Typography } from 'antd';
import { color, radius } from 'helpers/';
import { Form } from 'components/Form';
import { CurrencyInput } from 'components/CurrencyInput';

const { Text } = Typography;

export const PaymentMethod = styled.div`
  width: 100%;
  height: 96px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid ${color('neutral.200')};
  border-radius: ${radius('base')};
  margin-bottom: 24px;
`;

export const Image = styled.img`
  width: 134px;
  height: 44px;
`;

export const Description = styled(Text)`
  && {
    display: block;
    font-weight: 700;
    font-size: 14px;
    line-height: 22px;
    color: ${color('neutral.800')};
    text-align: center;
    margin-bottom: 10px;
  }
`;

export const FormLabel = styled(Form.Label)`
  cursor: pointer;
  text-align: center;
`;

export const StyledInput = styled(CurrencyInput)<{
  $isError?: boolean;
  $isActive?: boolean;
}>`
  border: none;
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  width: 100%;
  border-bottom: 1px solid ${color('neutral.400')};
  border-radius: 0;
  padding: 8px 0;
  height: auto;
  text-align: center;
  color: ${color('neutral.800')};
  ${({ $isError }) =>
    $isError &&
    css`
      background-color: ${color('neutral.0')};
      border-color: ${color('error')};
    `}
`;
