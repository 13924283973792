export type StorageKeys = 'wasEverSignedIn';
export const setItemToLocalStorage = (
  key: StorageKeys,
  value: string | object | boolean
): void => {
  const storageValue =
    typeof value === 'string' ? value : JSON.stringify(value);

  if (typeof window !== 'undefined') {
    localStorage.setItem(key, storageValue);
  }
};

export const getStringFromLocalStorage = (key: StorageKeys): string | null => {
  return typeof window !== 'undefined' ? localStorage.getItem(key) : null;
};

export const getObjectFromLocalStorage = (key: StorageKeys): object | null => {
  const storageData =
    typeof window !== 'undefined' ? localStorage.getItem(key) : null;

  if (!storageData) {
    return null;
  }

  try {
    return JSON.parse(storageData);
  } catch (_) {
    return null;
  }
};

export const removeItemFromLocalStorage = (key: StorageKeys): void => {
  if (typeof window !== 'undefined') {
    localStorage.removeItem(key);
  }
};
