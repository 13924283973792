import { useCallback } from 'react';
import { useLazyAffiliateTrackEventQuery } from 'store/api';

export enum AnalyticsEventTypes {
  AffiliateNavigate = 'affiliate_navigate',
}

const noop = () => {};

export const useAnalyticsEvent = (
  eventType: AnalyticsEventTypes,
  source: string
) => {
  const [affiliateTrackQuery] = useLazyAffiliateTrackEventQuery();

  const handleAfilliateNavigate = useCallback((target: string) => {
    affiliateTrackQuery({
      url: window.location.href,
      event_name: AnalyticsEventTypes.AffiliateNavigate,
      context: {
        source,
        target,
        referrer: document.referrer,
        agent: navigator.userAgent,
      },
    });
  }, []);

  switch (eventType) {
    case AnalyticsEventTypes.AffiliateNavigate:
      return handleAfilliateNavigate;
    default:
      return noop;
  }
};
