import { useEffect } from 'react';
import { useAppDispatch } from 'store/';
import { addErrorHandler, removeErrorHandler } from 'store/slices';

export const useHandleQueryError = (eventId: number, endpointName?: string) => {
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!endpointName) {
      return;
    }

    dispatch(addErrorHandler({ endpointName, eventId }));

    return () => {
      dispatch(removeErrorHandler({ endpointName, eventId }));
    };
  }, [endpointName, eventId]);
};
