import { Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled, { css } from 'styled-components';
import { navbarHeight, navbarWidth } from 'constants/';
import { color } from 'helpers/';
import { Button } from 'components/Button';

const { Text } = Typography;

export const Sidebar = styled.div`
  overflow-y: auto;
  width: ${navbarWidth}px;
  height: 100vh;
  background: ${color('neutral.0')};
  display: grid;
  grid-template-rows: 96px repeat(auto-fill, 96px);
`;

const itemCSS = css`
  width: 100%;
  height: ${navbarWidth}px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: ${color('neutral.0')};
  svg,
  span,
  div {
    transition: all 0.2s ease-in;
  }
`;

export const ItemContent = styled.div<{ $isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  margin: auto;
  color: ${color('neutral.600')};

  ${({ $isActive }) =>
    $isActive &&
    css`
      background: ${color('neutral.100')};
      border-radius: 16px;
      height: 100%;
      margin: 0;
      justify-content: center;
      color: ${color('neutral.900')};
    `}

  svg {
    margin: 0 auto;
    font-size: 24px;
  }
`;

export const LogoContainer = styled(Link)`
  ${itemCSS}
  img {
    height: 96px;
    width: 96px;
  }
`;

const MenuItemCSS = css<{ $isActive?: boolean }>`
  ${itemCSS}

  ${({ $isActive }) =>
    $isActive &&
    css`
      padding: 8px;
    `}

  cursor: pointer;
  transition: background-color 0.2s ease-in;
  &:hover {
    svg,
    span {
      color: ${color('primary')};
    }
  }
`;

export const MenuLinkItem = styled(Link)<{ $isActive?: boolean }>`
  ${MenuItemCSS}
  text-decoration: none;
`;
export const MenuButtonItem = styled.button<{ $isActive?: boolean }>`
  ${MenuItemCSS}
  border: 0;
`;

export const ItemName = styled(Text)<{ $isActive?: boolean }>`
  && {
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: ${color('neutral.600')};
    ${({ $isActive }) =>
      $isActive &&
      css`
        color: ${color('neutral.900')};
      `}
    text-decoration: none;
    margin-top: 4px;
  }
`;

export const TopBar = styled.div`
  height: ${navbarHeight}px;
  width: 100vw;
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  background: ${color('neutral.0')};
  border: inset 0px -1px 0px ${color('neutral.200')};
  align-items: center;
  img {
    height: 34px;
  }
  svg {
    font-size: 24px;
    padding: 4px;
  }
`;

export const MenuButton = styled(Button)`
  && {
    &.ant-btn-text:hover {
      background: none;
    }
  }
`;
