import { Drawer, Typography } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { color } from 'helpers/';

const { Text } = Typography;

export const StyledDrawer = styled(Drawer)`
  && {
    .ant-drawer-header {
      border-bottom: none;
      padding: 24px 24px 0 24px;
    }
    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }
    .ant-drawer-close {
      margin-inline-end: 0;
      font-size: 14px;
    }
    .ant-drawer-title {
      font-weight: 700;
      font-size: 18px;
      line-height: 23px;
      color: ${color('neutral.800')};
    }
  }
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

export const MenuItem = styled(Text)`
  && {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${color('neutral.800')};
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      font-size: 16px;
    }
  }
`;

export const MenuLink = styled(Link)`
  && {
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${color('neutral.800')};
    display: flex;
    align-items: center;
    gap: 10px;
    text-decoration: none;

    svg {
      font-size: 16px;
    }
  }
`;
