import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { LocationDescriptor, LocationDescriptorObject } from 'history';
import { useHistory } from 'react-router-dom';
import {
  HashLink as Link,
  HashLinkProps as LinkProps,
} from 'react-router-hash-link';
import { AnalyticsEventTypes, useAnalyticsEvent } from 'hooks';

const getLocationUrl = (location: LocationDescriptorObject): string => {
  const { pathname = '', search, hash } = location;
  const url = new URL(pathname, window.location.origin);

  if (search) url.search = search;
  if (hash) url.hash = hash;

  return url.toString();
};

const appendSearchParams = (search: string): string => {
  const prevSearch = new URLSearchParams(window.location.search);
  const newSearch = new URLSearchParams(search);

  newSearch.forEach((value, key) => {
    prevSearch.set(key, value);
  });

  return prevSearch.toString();
};

const getLocationDescriptorWithSearchParams = (
  location: LocationDescriptor
): LocationDescriptorObject => {
  if (typeof location === 'string') {
    const url = new URL(location, window.location.href);
    url.search = appendSearchParams(url.search);
    return url;
  }

  if (!location.search) {
    return { ...location, search: window.location.search };
  }

  return {
    ...location,
    search: appendSearchParams(location.search),
  };
};

const withAnalyticsLink = <
  P extends Omit<LinkProps, 'to'> & {
    to: LocationDescriptor | any;
    source: string;
  }
>(
  Component: React.ComponentType<Omit<P, 'source'>>
): React.ComponentType<P> => {
  return ({ source, ...props }: P) => {
    const trackAffiliateNavigate = useAnalyticsEvent(
      AnalyticsEventTypes.AffiliateNavigate,
      source
    );

    const ref = useRef<HTMLAnchorElement>(null);

    const { to } = props;

    const locationWithSearch = useMemo(
      () => getLocationDescriptorWithSearchParams(to),
      [to]
    );

    useEffect(() => {
      if (!ref.current) {
        return;
      }

      const handleClick = () => {
        trackAffiliateNavigate(getLocationUrl(locationWithSearch));
      };

      ref.current.addEventListener('click', handleClick);

      return () => {
        ref.current?.removeEventListener('click', handleClick);
      };
    }, [locationWithSearch, trackAffiliateNavigate]);

    const locationDescriptor = useMemo(
      () => ({
        pathname: locationWithSearch.pathname,
        search: locationWithSearch.search,
        state: (to as LocationDescriptorObject)?.state,
        hash: locationWithSearch.hash,
        key: (to as LocationDescriptorObject)?.key,
      }),
      [to, locationWithSearch]
    );

    return <Component {...(props as P)} to={locationDescriptor} ref={ref} />;
  };
};

export const useAnalyticsLink = (source: string) => {
  const history = useHistory();

  const trackAffiliateNavigate = useAnalyticsEvent(
    AnalyticsEventTypes.AffiliateNavigate,
    source
  );

  return useCallback(
    (to: LocationDescriptor) => {
      const locationWithSearch = getLocationDescriptorWithSearchParams(to);
      trackAffiliateNavigate(getLocationUrl(locationWithSearch));
      history.push({
        pathname: locationWithSearch.pathname,
        search: locationWithSearch.search,
        state: (to as LocationDescriptorObject)?.state,
        hash: locationWithSearch.hash,
        key: (to as LocationDescriptorObject)?.key,
      });
    },
    [trackAffiliateNavigate]
  );
};

export const useAnalyticsTracking = (source: string) => {
  const trackAffiliateNavigate = useAnalyticsEvent(
    AnalyticsEventTypes.AffiliateNavigate,
    source
  );

  useEffect(() => {
    const locationWithSearch = getLocationDescriptorWithSearchParams(
      window.location
    );
    trackAffiliateNavigate(getLocationUrl(locationWithSearch));
  }, [trackAffiliateNavigate]);
};

export const AnalyticsLink = withAnalyticsLink(Link);
