import React from 'react';
import { SelectProps } from 'antd';
import { CaretDown } from 'assets/';
import { Container, Select as StyledSelect } from './styled';
import { Tag } from './components/Tag';

const renderWithContainer = (
  menu: React.ReactElement<any, string | React.JSXElementConstructor<any>>
) => <Container>{menu}</Container>;

export const Select = ({ ...props }: SelectProps) => {
  return (
    <StyledSelect
      suffixIcon={<CaretDown />}
      tagRender={Tag}
      dropdownRender={renderWithContainer}
      listItemHeight={10}
      {...props}
    />
  );
};
