import { useEffect } from 'react';
import { SerializedError } from '@reduxjs/toolkit';
import {
  useLazyGetResearchDefenderTokenQuery,
  useResearchDefenderTokenMutation,
} from 'store/api';
import { log } from 'utils/logService';
import { EventIds } from 'constants/';

export const useResearchDefenderToken = () => {
  const [getToken] = useLazyGetResearchDefenderTokenQuery();
  //   const getToken = () =>
  //     Promise.resolve({ data: { results: ['rd-token-override'] } });
  const [sendToken] = useResearchDefenderTokenMutation();

  useEffect(() => {
    // return;
    getToken().then((result) => {
      const { error } = result as { error: SerializedError };
      if (error) {
        log.error(
          'Query to get research defender token has failed',
          EventIds.ResearchDefenderTokenQueryError,
          error
        );
        return;
      }

      console.log('Research Defender token result:', result);
      sendToken({ researchdefender_tokens: result.data?.results! }).then(
        (result) => {
          const { error } = result as { error: SerializedError };
          if (error) {
            log.error(
              'Mutation to send research defender token has failed',
              EventIds.ResearchDefenderTokenMutationError,
              error
            );
          }
        }
      );
    });
  }, [getToken, sendToken]);
};
