import { useHistory } from 'react-router';
import { Button } from 'components/Button';
import { OnboardingModalConfig } from '../../config';
import { useOnboardingModal } from '../../OnboardingModalContext';

export const OnboardingFooter = () => {
  const history = useHistory();
  const { close, config } = useOnboardingModal();

  const getActionHandler =
    (action: OnboardingModalConfig['actions'][0]) => () => {
      if (action.isCloseAction) {
        close();
      }

      if (action.href) {
        history.push(action.href);
      }
    };

  if (!config) {
    return null;
  }

  return (
    <>
      {config.actions.map((action, index) => (
        <Button
          key={index}
          onClick={getActionHandler(action)}
          type={action.type}
          block
        >
          {action.label}
        </Button>
      ))}
    </>
  );
};
