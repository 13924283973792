import React from 'react';
import { CloseGrey } from 'assets/';
import { Label, Container } from './styled';

export interface TagProps {
  label: React.ReactNode;
  onClose: () => void;
  closable: boolean;
}

export const Tag = ({ label, onClose, closable }: TagProps) => {
  return (
    <Container>
      <Label>{label}</Label>
      {closable && <CloseGrey onClick={onClose} />}
    </Container>
  );
};
