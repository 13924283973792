import { useEffect, useMemo } from 'react';
import { Modal } from 'components/Modal';
import OnboardingModalBanner from 'assets/OnboardingModalBanner.png';
import { useUpdateUserStateMutation } from 'store/api';
import { useOnboardingModal } from './OnboardingModalContext';
import { BannerModal, OnboardingSteps } from './components';
import { OnboardingFooter } from './components/OnboardingFooter';

export const OnboardingModal = () => {
  const { isVisible, close, config } = useOnboardingModal();

  const [updateUserState] = useUpdateUserStateMutation();

  useEffect(() => {
    if (!config || !isVisible) {
      return;
    }

    const timeoutId = setTimeout(() => {
      updateUserState({
        context: config.context,
        action: 'POPUP_SHOWN',
      });
    }, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [config, isVisible]);

  const modalProps = useMemo(
    () => ({
      title: config?.title,
      subTitle: config?.description,
      isVisible,
      onCancel: close,
      showCancelButton: false,
      showOkButton: false,
      actionsVertical: true,
      destroyOnClose: true,
      centered: true,
    }),
    [config, close]
  );

  if (!config) {
    return null;
  }

  if (config.isBannerModal) {
    return (
      <BannerModal
        {...modalProps}
        banner={OnboardingModalBanner}
        footer={<OnboardingFooter />}
      >
        <OnboardingSteps />
      </BannerModal>
    );
  }

  return (
    <Modal {...modalProps} footer={<OnboardingFooter />}>
      <OnboardingSteps />
    </Modal>
  );
};
