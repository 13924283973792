import { Theme } from './types';

export const defaultTheme: Theme = {
  palette: {
    primary: {
      0: '#FFFFFF',
      100: '#FEEEEE',
      200: '#FBDBDD',
      300: '#F7B7BA',
      400: '#F39398',
      500: '#EF6F75',
      600: '#EB4B53',
      700: '#B0383E',
      800: '#76262A',
      900: '#3B1315',
      1000: '#180808',
    },
    error: {
      0: '#FFFFFF',
      100: '#FDECF1',
      200: '#FBD8E3',
      300: '#F6B2C7',
      400: '#F28BAA',
      500: '#ED658E',
      600: '#E93E72',
      700: '#AF2F56',
      800: '#751F39',
      900: '#3A101D',
      1000: '#17060B',
    },
    warning: {
      0: '#FFFFFF',
      100: '#FFF4ED',
      200: '#FDE8D9',
      300: '#FBD1B3',
      400: '#FAB98E',
      500: '#F8A268',
      600: '#F68B42',
      700: '#B96832',
      800: '#7B4621',
      900: '#3E2311',
      1000: '#190E07',
    },
    neutral: {
      0: '#FFFFFF',
      100: '#F7F7FA',
      200: '#E2E3E9',
      300: '#C6C8D2',
      400: '#A9ACBC',
      500: '#8D91A5',
      600: '#70758F',
      700: '#54586B',
      800: '#383B48',
      900: '#1C1D24',
      1000: '#0B0C0E',
    },
    success: {
      0: '#FFFFFF',
      100: '#EAF6F1',
      200: '#D3ECE1',
      300: '#A7D8C3',
      400: '#7BC5A6',
      500: '#4FB188',
      600: '#239E6A',
      700: '#1A7750',
      800: '#124F35',
      900: '#09281B',
      1000: '#04100B',
    },
    info: {
      0: '#FFFFFF',
      100: '#F0F5FF',
      200: '#D6E4FF',
      300: '#ADC6FF',
      400: '#85A5FF',
      500: '#597EF7',
      600: '#2F54EB',
      700: '#1D39C4',
      800: '#10239E',
      900: '#061178',
      1000: '#030852',
    },
  },
  // TODO: remove and use hardcoded values in place or collect all radii and build new system
  radius: {
    round: '100rem',
    xxl: '15px',
    xl: '10px',
    lg: '8px',
    base: '6px',
    sm: '4px',
    xs: '2px',
  },
  breakpoint: {
    xs: 320,
    sm: 576,
    md: 992,
    lg: 1200,
    xl: 1439,
  },
};
